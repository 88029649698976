// initial state
import API from '@/utils/api'
import {
    Message
} from 'element-ui'
const state = {
    botQAList: [],
    botQuestion: {},
    postList: [],
    changeBotQAList: [],
    changeBotQuestion: [],
    replyMessage: '',
    messages: [],
    parentid: '',
    pageSize: 15, // 每页显示的个数
    pageNumber: null, // 有多少页
    pageIndex: 0, // 当前页码
    totalNumber: 0, // 有多少条记录
    loading: false,
    categories: []
}

// getters
const getters = {
    posts(state) {
        let tempList = []
        if (state.postList.length) {
            let res = state.postList.splice(0, 5)
            res.forEach(post => {
                tempList.push({
                    value: post.title,
                    id: post.id
                })
            })
        }
        return tempList
    },
}

//mutations
const mutations = {
    setBotQAList(state, result) {
        state.botQAList = [...state.botQAList, ...result.list]
        state.pageNumber = result.pageNumber
        state.totalNumber = result.totalNumber
    },
    setBotQuestion(state, result) {
        state.botQuestion = result
    },
    setSearchPosts(state, result) {
        state.postList = result
    },
    deleteBotQA(state, id) {
        if (id) {
            state.botQAList.splice(state.botQAList.findIndex(item => item.id === id), 1)
        } else {
            state.botQAList.splice(state.botQAList.findIndex(item => item.id === undefined), 1)
        }
    },
    addBotQA(state, botQA) {
        state.botQAList.splice(0, 0, botQA)
    },
    updateBotQA(state, payload) {
        let index = state.botQAList.findIndex(item => item.id === payload.id)
        if (index !== -1) {
            let cindex = state.changeBotQAList.findIndex(item => item.id === payload.id)
            if (cindex !== -1) {
                state.changeBotQAList[cindex][payload.field] = payload.value
            } else {
                state.changeBotQAList.push({ id: payload.id, [payload.field]: payload.value, reply_type: state.botQAList[index].reply_type })
            }
        }
    },
    updateBotQuestion(state, payload) {
        let index = state.botQuestion.findIndex(item => item.id === payload.id)
        if (index !== -1) {
            let cindex = state.changeBotQuestion.findIndex(item => item.id === payload.id)
            if (cindex !== -1) {
                state.changeBotQuestion[cindex] = payload.value
            } else {
                state.changeBotQuestion.push({ id: payload.id, question: payload.value })
            }
        }
    },
    setBotReply(state, result) {
        let reply = [], replyType = 'Text'
        if (result.noReply) {
            reply.push({ msg: result.noReply, type: 'answer', replyType: replyType })
        } else {
            if (result.commonReply) {
                let commonReply = JSON.parse(result.commonReply)
                reply.push({ msg: commonReply.reply, type: 'answer', replyType: replyType })
                if (commonReply.parentid) {
                    state.parentid = commonReply.parentid
                }
            } if (result.postReply) {
                let postStr = ''
                JSON.parse(result.postReply).forEach(post => {
                    postStr += `${post.title}<br>`
                })
                reply.push({ msg: postStr, type: 'answer', replyType: 'Post' })
            }
        }
        state.messages = [...state.messages, ...reply]
        state.replyMessage = ''
    },
    addMessage(state, message) {
        state.messages.push({ msg: message.msg, type: message.type, replyType: message.replyType })
    },
    emptyParentId(state) {
        state.parentid = ''
    },
    emptyBotQAList(state) {
        state.botQAList = []
    },
    emptyBotQuestion(state) {
        state.botQuestion = []
    },
    emptyBotQAPageNumber(state) {
        state.pageNumber = null
    },
    switchBotQALoading(state) {
        state.loading = !state.loading
    },
    setBotQACategories(state, result) {
        let tempList = ['全部类别']
        result.forEach(category => {
            tempList.push(category.category)
        })
        state.categories = tempList
    }
}

//actions
const actions = {
    async getBotQAList({ state, rootState, commit }, payload) {
        Object.assign(payload, { hospitalid: rootState.index.userInfo.hospitalid, pageSize: state.pageSize })
        let res = await API.getBotQAList(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setBotQAList', res.result)
            commit('switchBotQALoading')
        } else {
            console.log(res.err)
        }
    },
    async getBotQuestion({ commit }, bot_qaid) {
        let res = await API.getBotQuestion(bot_qaid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setBotQuestion', res.result)
        } else {
            console.log(res.err)
        }
    },
    async getBotPostList({ commit }, searchKey) {
        let res = await API.getBotPostList(searchKey)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setSearchPosts', res.result)
        } else {
            console.log(res.err)
        }
    },
    async deleteBotQA({ commit }, id) {
        if (id) {
            let res = await API.deleteBotQA(id)
            if (res.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (res.status === 200) {
                commit('deleteBotQA', id)
                Message({
                    message: '删除成功',
                    type: 'success'
                })
            } else {
                console.log(res.err)
            }
        } else {
            commit('deleteBotQA', id)
        }
    },
    async createBotQA({ commit, rootState }, payload) {
        Object.assign(payload, { hospitalid: rootState.index.userInfo.hospitalid })
        let res = await API.createBotQA(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('addBotQA', res.result[0])
            Message({
                message: '创建成功',
                type: 'success'
            })
            return
        } else {
            console.log(res.err)
        }
    },
    async batchUpdateBotQA({ state }) {
        if (!state.changeBotQAList.length) {
            Message({
                message: '没有任何修改',
                type: 'warning'
            })
            return
        }
        for (let item of state.changeBotQAList) {
            if (item.reply_type === 'Post' && item.reply) {
                if (!JSON.parse(item.reply).postids.length) {
                    Message({
                        message: '文章不能为空',
                        type: 'warning'
                    })
                    return
                }
            }
        }
        let res = await API.batchUpdateBotQA(state.changeBotQAList)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            Message({
                message: '更新成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    },
    async batchDeleteAndInsertBotQuestion({ state }) {
        if (!state.botQuestion.questions) {
            Message({
                message: '问题不能为空',
                type: 'warning'
            })
            return
        }
        let res = await API.batchDeleteAndInsertBotQuestion(state.botQuestion)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            Message({
                message: '更新成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    },
    async sendChatMessage({ commit, state }, payload) {
        let res = await API.sendChatMessage({ type: 'Text', message: payload.message, isTest: true, parentid: state.parentid })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            // console.log(res)
            commit('emptyParentId')
            commit('setBotReply', res.result)
        } else {
            console.log(res.err)
        }
    },
    async getBotQACategories({ commit, rootState }) {
        let res = await API.getBotQACategories(rootState.index.userInfo.hospitalid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setBotQACategories', res.result)
        } else {
            console.log(res.err)
        }
    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
