/* eslint-disable no-prototype-builtins */
import moment from 'moment'
import API from '@/utils/api'
import { Message } from 'element-ui'
import { Notification } from 'element-ui';

const state = {
    inquiryForms: {},
    currentInquiry: {},
    healthRecords: {},
    patientid2prescriptions: {},
    careRecords: {},
    subscribes: {},
    inquiryImages: [],
    currentHealthRecord: {},
    prescriptionStatusMapping: {
        'Pending': '审核中',
        'Approved': '已通过',
        'Delivered': '已取药',
        'Canceled': '已取消'
    },
    subscribeStatusTypeMapping: {
        'Pending': '',
        'Completed': 'success',
        'Canceled': 'info',
        'Expired': 'danger'
    },
    careQuestionMapping: (() => {
        let map = new Map()
        map.set('未见明显好转', 'warning')
        map.set('其他，不要再问我', 'info')
        map.set('有好转，继续治疗', 'success')
        map.set('已康复', 'success')
        map.set('已转诊治疗', 'info')
        return map
    })(),
    logTotalNumber: 0,
    downloadUrl: '',
    pageSize: 15,
    pageNumber: null,
    treatmentLogs: [],
    loading: false,
    unSaveClients: [],
    familyMember: {},
    medicinePerUnitDialog: false,
    medicine: '',
    secondActiveClient: { healthRecord: {}, prescription: {}, careList: [] },
    secondHR: {},
    currentHRCareInfo: {},
    treatmentDetailInfo: {},
    todaySubscribe: {},
    schedules: [],
    originSchedules: []
}

const getters = {
    activeHealthRecord(state, getters, rootState, rootGetters) {
        if (rootGetters['chat/hasActiveClient']) {
            let activeClient = rootGetters['chat/activeClient']
            let activeHealthRecord = state.healthRecords[activeClient.id] ? state.healthRecords[activeClient.id] : {}
            if (rootState.index.hrTemplates.length && activeHealthRecord.id) {
                let template = rootState.index.hrTemplates.find((item) => item.id === activeHealthRecord.hr_templateid)
                if (!template) {
                    Message({
                        message: '没有找到病历模板',
                        type: 'error'
                    })
                    return
                }
                let sections = []
                let visible_sections = JSON.parse(template.visible_sections)
                for (let item in visible_sections) {
                    sections.push({
                        label: item,
                        fieldName: visible_sections[item]
                    })
                }
                activeHealthRecord.sections = sections
            }
            if (activeHealthRecord.age) {
                let ages = activeHealthRecord.age.toFixed(4).split('.') // e.g., 3.1207 表示 3岁12月07天
                let ageYear = parseInt(ages[0]),
                    ageMonths = parseInt(ages[1].substring(0, 2)),
                    ageDays = parseInt(ages[1].substring(2, 4))
                ageYear = ageYear > 0 ? ageYear + '岁' : ''
                ageMonths = ageMonths > 0 ? ageMonths + '个月' : ''
                ageDays = ageDays > 0 ? ageDays + '天' : ''
                activeHealthRecord['ageText'] = ageYear + ageMonths + ageDays
            }
            return activeHealthRecord
        } else {
            return {}
        }
    },
    activeInquiryForm(state, getters, rootState, rootGetters) {
        if (rootGetters['chat/hasActiveClient']) {
            let activeClient = rootGetters['chat/activeClient']
            return state.inquiryForms[activeClient.id] ? state.inquiryForms[activeClient.id] : {}
        } else {
            return {}
        }
    },
    activePrescriptions(state, getters, rootState, rootGetters) {
        if (rootGetters['chat/hasActiveClient']) {
            let activeClient = rootGetters['chat/activeClient']
            return state.patientid2prescriptions[`${activeClient.id}`] ? state.patientid2prescriptions[`${activeClient.id}`] : []
        } else {
            return []
        }
    },
    activeCareRecords(state, getters, rootState, rootGetters) {
        if (rootGetters['chat/hasActiveClient']) {
            let activeClient = rootGetters['chat/activeClient']
            return state.careRecords[`${activeClient.id}`] ? state.careRecords[`${activeClient.id}`] : []
        } else {
            return []
        }
    },
    activeSubscribes(state, getters, rootState, rootGetters) {
        if (rootGetters['chat/hasActiveClient']) {
            let activeClient = rootGetters['chat/activeClient']
            return state.subscribes[`${activeClient.id}`] ? state.subscribes[`${activeClient.id}`] : []
        } else {
            return []
        }
    },
    activeTodaySubscribe(state, getters, rootState, rootGetters) {
        if (rootGetters['chat/hasActiveClient']) {
            let activeClient = rootGetters['chat/activeClient']
            return state.todaySubscribe[`${activeClient.id}`] ? state.todaySubscribe[`${activeClient.id}`] : []
        } else {
            return []
        }
    },
    selectedPrescription(state, getters, rootState, rootGetters) {
        if (rootGetters['chat/hasActiveClient']) {
            // let activeClient = rootGetters['chat/activeClient']
            let activeHealthRecord = getters.activeHealthRecord
            return state.patientid2prescriptions[`${activeHealthRecord.id}`] ? state.patientid2prescriptions[activeHealthRecord.id] : {}
        } else {
            return {}
        }
    },
    activeTreatmentLogDetailInfo(state, getters, rootState) {
        let allSections = rootState.index.allSections, sections = []
        for (let key in allSections) {
            if (state.treatmentDetailInfo[allSections[key]]) {
                sections.push({
                    field: key,
                    value: state.treatmentDetailInfo[allSections[key]]
                })
            }
        }
        return Object.assign({}, state.treatmentDetailInfo, { sections: sections })
    },
    activeInquiryImages(state, getters, rootState, rootGetters) {
        if (rootGetters['chat/hasActiveClient']) {
            let activeClient = rootGetters['chat/activeClient']
            return state.inquiryImages[activeClient.id] ? state.inquiryImages[activeClient.id] : []
        } else {
            return []
        }
    },
    subActiveHealthRecord(state, getters, rootState) {
        if (state.secondActiveClient.healthRecord.id && rootState.index.hrTemplates.length) {
            let subActiveHealthRecord = state.secondActiveClient.healthRecord
            let template = rootState.index.hrTemplates.find(item => item.id === subActiveHealthRecord.hr_templateid)
            if (!template) {
                Message({
                    message: '没有找到病历模板',
                    type: 'error'
                })
                return
            }
            let sections = []
            let visible_sections = JSON.parse(template.visible_sections)
            for (let item in visible_sections) {
                sections.push({
                    label: item,
                    fieldName: visible_sections[item]
                })
            }
            subActiveHealthRecord.sections = sections
            subActiveHealthRecord.print_inquiry_date = subActiveHealthRecord.inquiry_date ? moment(subActiveHealthRecord.inquiry_date).format('LLL') : ''
            if (subActiveHealthRecord.age) {
                let ages = subActiveHealthRecord.age.toFixed(4).split('.') // e.g., 3.1207 表示 3岁12月07天
                let ageYear = parseInt(ages[0]),
                    ageMonths = parseInt(ages[1].substring(0, 2)),
                    ageDays = parseInt(ages[1].substring(2, 4))
                ageYear = ageYear > 0 ? ageYear + '岁' : ''
                ageMonths = ageMonths > 0 ? ageMonths + '个月' : ''
                ageDays = ageDays > 0 ? ageDays + '天' : ''
                subActiveHealthRecord['ageText'] = ageYear + ageMonths + ageDays
            }
            return subActiveHealthRecord
        } else {
            return {}
        }
    },
    activeSchedules(state) {
        let schedules = []
        state.schedules.forEach(item => {
            item.checked = item.checked || false
            item.showEditLimit = item.checked || false
            if (item.start_time) {
                let startHour = moment(new Date(item.start_time)).format('HH:mm')
                let endHour = moment(new Date(item.end_time)).format('HH:mm')
                item.timeInterval = `${startHour}-${endHour}`
            }
            schedules.push(item)
        })
        return schedules
    }
}

const mutations = {
    emptyFormStates(state, activeClient) {
        // let inquiryForm = {}, healthRecord = {}, prescription = {}, careRecord = {}
        if (activeClient && activeClient.id) {
            state.inquiryForms = Object.assign({}, { [activeClient.id]: state.inquiryForms[activeClient.id] })
            state.healthRecords = Object.assign({}, { [activeClient.id]: state.healthRecords[activeClient.id] })
            let hrid = state.healthRecords[activeClient.id].id
            state.patientid2prescriptions = Object.assign({}, { [hrid]: state.patientid2prescriptions[hrid] })
            state.careRecords = Object.assign({}, { [activeClient.id]: state.careRecords[activeClient.id] })
        } else {
            state.inquiryForms = {}
            state.healthRecords = {}
            state.patientid2prescriptions = {}
            state.careRecords = {}
        }
    },
    updateInquiryForm(state, question) {
        state.inquiryForms[question.userid].some(element => {
            if (element.id === question.id) {
                element.answer = question.answer
                let index = state.inquiryForms[question.userid].changeQuestions.findIndex(element => element.questionid === question.id)
                if (index === -1) {
                    state.inquiryForms[question.userid].changeQuestions.push({
                        id: element.item_id,
                        inquiryid: question.inquiryid,
                        questionid: element.id,
                        answer: question.answer,
                        positive_option: element.positive_option
                    })
                } else {
                    state.inquiryForms[question.userid].changeQuestions[index].answer = question.answer
                }
                return true
            }
        })
    },
    addInquiryForm(state, payload) {
        let inquiryForms = payload.inquiryForms, forceRefresh = payload.forceRefresh
        inquiryForms.questions = inquiryForms.questions.length ? inquiryForms.questions : []
        Object.assign(inquiryForms.questions, { changeQuestions: [] })
        let images = []
        inquiryForms.questions.forEach(item => {
            if (item.urls) {
                let title = ''
                if (item.question.indexOf('处方单') !== -1) title = '处方单'
                else if (item.question.indexOf('检查单') !== -1) title = '检查单'
                else if (item.question.indexOf('检查资料') !== -1) title = '其他资料'
                images.push({ title: title, urls: item.urls })
            }
        })
        if (!state.inquiryForms.hasOwnProperty(inquiryForms.id) || forceRefresh) {
            state.inquiryForms = Object.assign({}, state.inquiryForms, { [inquiryForms.id]: inquiryForms.questions })
            state.inquiryImages = Object.assign({}, state.inquiryImages, { [inquiryForms.id]: images })
        } else if (state.inquiryForms.hasOwnProperty(inquiryForms.id)) {
            state.inquiryForms[inquiryForms.id] = inquiryForms.questions
            state.inquiryImages[inquiryForms.id] = images
        }
    },
    addHealthRecord(state, hr) {
        hr.print_inquiry_date = hr.inquiry_date ? moment(hr.inquiry_date).format('LLL') : ''
        hr.created_date = moment(hr.created_date).calendar()
        hr.summary = hr.summary || ''
        hr.symptom = hr.symptom || ''
        hr.sick_history = hr.sick_history || ''
        hr.treatment_history = hr.treatment_history || ''
        hr.allergic_history = hr.allergic_history || ''
        hr.clinical_inspection = hr.clinical_inspection || ''
        hr.clinical_treatment = hr.clinical_treatment || ''
        hr.clinical_diagnose = hr.clinical_diagnose || ''
        hr.clinical_process = hr.clinical_process || ''
        hr.auxiliary_inspection = hr.auxiliary_inspection || ''
        hr.body_ispection = hr.body_ispection || ''
        hr.dispose = hr.dispose || ''
        hr.special_inspection = hr.special_inspection || ''
        hr.selectedTypes = hr.selectedTypes || []
        hr.symptom = hr.symptom || ''
        hr.personal_history = hr.personal_history || ''
        hr.weight = hr.weight || null
        hr.doctor_signature_url = hr.doctor_signature_url || null
        hr.approval_doctor_signature_url = hr.approval_doctor_signature_url || null
        hr.trackTemplateid = hr.hr_templateid || ''
        hr.files = hr.files || []
        hr.formatFiles = hr.formatFiles || []
        hr.related_id = hr.related_id || ''
        hr.copy_related_id = hr.related_id || ''
        if (!state.healthRecords.hasOwnProperty(hr.patientid)) {
            state.healthRecords = Object.assign({}, state.healthRecords, { [hr.patientid]: hr })
        } else {
            state.healthRecords[hr.patientid] = hr
        }
    },
    addSubscribes(state, result) {
        let todaySubscribe = {}
        result.subscribes.forEach(item => {
            if (moment(new Date()).format("YYYY-MM-DD") ===
                moment(new Date(item.start_time)).format("YYYY-MM-DD") && item.status !== 'Canceled') {
                item.subscribe_time = `${moment(new Date(item.start_time)).format("HH:mm")} - ${moment(new Date(item.end_time)).format("HH:mm")}`
                todaySubscribe = item
            }
            item.type = state.subscribeStatusTypeMapping[item.status]
        })
        if (!state.todaySubscribe.hasOwnProperty(result.userid)) {
            state.todaySubscribe = Object.assign({}, state.todaySubscribe, { [result.userid]: todaySubscribe })
        } else {
            state.todaySubscribe[result.userid] = todaySubscribe
        }
        if (!state.subscribes.hasOwnProperty(result.userid)) {
            state.subscribes = Object.assign({}, state.subscribes, { [result.userid]: result.subscribes })
        } else {
            state.subscribes[result.userid] = result.subscribes
        }
    },
    addUploadHRFiles(state, result) {
        let activeHealthRecord = JSON.parse(JSON.stringify(state.healthRecords[result.patientid]))
        let id = activeHealthRecord.formatFiles.length ? activeHealthRecord.formatFiles[activeHealthRecord.formatFiles.length - 1].id + 1 : 0
        activeHealthRecord.formatFiles = [...activeHealthRecord.formatFiles, { url: result.fullUrl, id: id }]
        activeHealthRecord.files = [...activeHealthRecord.files, result.path]
        state.healthRecords[result.patientid] = activeHealthRecord
    },
    addCareRecords(state, payload) {
        let result = payload.result
        if (result.length) {
            result.forEach((care) => {
                care.created_date = moment(care.created_date).format('LL')
                care.feeling_better = care.feeling_better || ''
                care.recovered = care.recovered || ''
                care.side_effect = care.side_effect || ''
                care.side_effect_description = care.side_effect_description || ''
                care.isConfirmed = care.isConfirmed || ''
                care.isChanged = false
                care.tag = state.careQuestionMapping.get(care.feeling_better) ? state.careQuestionMapping.get(care.feeling_better) : ''
            })
            if (!state.careRecords.hasOwnProperty(payload.patientid)) {
                state.careRecords = Object.assign({}, state.careRecords, { [payload.patientid]: result })
            } else {
                if (payload.type && payload.type === 'update') {
                    let careList = result.concat(state.careRecords[payload.patientid])
                    state.careRecords[payload.patientid] = careList
                } else {
                    state.careRecords[payload.patientid] = result
                }
            }
        } else {
            state.careRecords = Object.assign({}, state.careRecords, { [payload.patientid]: [] })
        }
    },
    addPrescriptions(state, payload) {
        if (!state.patientid2prescriptions.hasOwnProperty(payload.health_recordid)) {
            let prescription = payload.result.length ? payload.result[0] : {}
            prescription.print_inquiry_date = prescription.length ? moment(prescription.created_date).format('LLL') : ''
            prescription.department = prescription.department || ''
            prescription.amount = prescription.amount || ''
            prescription.registration_fee = prescription.registration_fee || ''
            prescription.treatment_fee = prescription.treatment_fee || ''
            prescription.medicine_fee = prescription.medicine_fee || ''
            prescription.fee_type = prescription.fee_type || ''
            prescription.allergic_history = prescription.allergic_history || ''
            prescription.clinical_diagnose = prescription.clinical_diagnose || ''
            prescription.doctor_signature_url = prescription.doctor_signature_url || ''
            prescription.comment = prescription.comment || ''
            prescription.pharmacist_name = prescription.pharmacist_name || ''
            prescription.allocation_doctor_name = prescription.allocation_doctor_name || ''
            prescription.approval_doctor_name = prescription.approval_doctor_name || ''
            prescription.dispensing_doctor_name = prescription.dispensing_doctor_name || ''
            prescription.selectedType = ''
            prescription.selectedProcess = '选择类型'
            prescription.medicines = payload.medicines
            state.patientid2prescriptions = Object.assign({}, state.patientid2prescriptions, { [payload.health_recordid]: prescription })
        }
    },
    updateSelectedPrescription(state, payload) {
        let sub = state.patientid2prescriptions[payload.patientid] && state.patientid2prescriptions[payload.patientid].length > 0 ? state.patientid2prescriptions[payload.patientid][0] : {}
        sub[payload.key] = payload.value
    },
    resetActiveHealthRecord(state, patientid) {
        let activeHealthRecord = state.healthRecords[patientid]
        activeHealthRecord.medicines.forEach(medicine => {
            medicine.checked = false
            medicine.dosage = null
            medicine.unit = null
            medicine.frequency = null
        })
    },
    updateActiveHealthRecord(state, payload) {
        let activeHealthRecord = JSON.parse(JSON.stringify(state.healthRecords[payload.patientid]))
        if (payload.isField) {
            activeHealthRecord[payload.field] = payload.value
        } else if (payload.isMedicine) {
            activeHealthRecord.medicines.forEach(medicine => {
                if (medicine.id === payload.medicine.id) {
                    console.log('updateActiveHealthRecord')
                    medicine[payload.medicine.field] = payload.medicine.value
                }
            })
        } else if (payload.isFamilyMember) {
            activeHealthRecord.familyMember[payload.field] = payload.value
        }
        state.healthRecords[payload.patientid] = activeHealthRecord
    },
    addMedicineToHealthRecords(state, payload) {
        Object.values(state.healthRecords).forEach(hr => {
            let medicine = {}
            Object.keys(payload.medicine).forEach(key => {
                medicine[key] = payload.medicine[key]
            })
            medicine.checked = false
            medicine.dosage = null
            medicine.unit = null
            medicine.frequency = null
            medicine.dosage_type = 'mg'
            medicine.per_unit = 'mg'
            medicine.per_unit_options = ['mg', payload.medicine.unit_type]
            medicine.per_quantity = null
            medicine.delivery = null
            hr.medicines = [...hr.medicines, medicine]
        })
    },
    updateActiveCareRecords(state, payload) {
        state.careRecords[payload.patientid].some(care => {
            if (care.id === payload.id) {
                care[payload.field] = payload.value
                care["tag"] = state.careQuestionMapping.get(care.feeling_better) ? state.careQuestionMapping.get(care.feeling_better) : ''
                care["isChanged"] = true
                return true
            }
        })
    },
    loadLogs(state, result) {
        result.list.forEach(log => {
            log.gender = log.gender == 0 ? '女' : '男'
            log.onset_date = log.onset_date ? moment(log.onset_date).format('yyyy-MM-DD') : ''
            log.inquiry_date = log.inquiry_date ? moment(log.inquiry_date).format('yyyy-MM-DD') : ''
            log.is_first_visit = log.is_first_visit ? '初诊' : '复诊'
            log.is_infectious = log.is_infectious ? '是' : '否'
        })
        state.treatmentLogs = [...state.treatmentLogs, ...result.list]
        state.logTotalNumber = result.totalNumber
        state.pageNumber = result.pageNumber
    },
    setDownloadUrl(state, downloadUrl) {
        state.downloadUrl = API.baseURL + downloadUrl
    },
    setLogsTotalNumber(state, totalNumber) {
        state.logTotalNumber = totalNumber
    },
    setCurrentInquiry(state, ci) {
        state.currentInquiry = ci
    },
    switchLogLoading(state) {
        state.loading = !state.loading
    },
    emptyTreatmentLog(state) {
        state.treatmentLogs = []
    },
    emptyLogPageNumber(state) {
        state.pageNumber = null
    },
    updatePrescriptionState(state, payload) {
        let prescription = JSON.parse(JSON.stringify(state.patientid2prescriptions[payload.health_recordid]))
        prescription[payload.field] = payload.value
        state.patientid2prescriptions[payload.health_recordid] = prescription
    },
    setCurrentHealthRecord(state, result) {
        state.currentHealthRecord = result
    },
    updateHealthRecordFamilyInfo(state, result) {
        let activeHealthRecord = state.healthRecords[result.patientid]
        activeHealthRecord.name = result.name
        activeHealthRecord.age = result.age
        activeHealthRecord.gender = result.gender
        activeHealthRecord.weight = result.weight
        activeHealthRecord.patient_phone = result.patient_phone
        activeHealthRecord.patient_address = result.patient_address
        activeHealthRecord.inquiry_date = result.inquiry_date
        activeHealthRecord.print_inquiry_date = activeHealthRecord.inquiry_date ? moment(activeHealthRecord.inquiry_date).format('LLL') : ''
    },
    pushPopMedicine(state, payload) {
        let medicines = state.healthRecords[payload.patientid].medicines
        if (payload.type === 'push') {
            let index = medicines.findIndex(item => item.id === payload.medicine.id)
            if (index === -1) {
                medicines.push(payload.medicine)
            }
        } else {
            medicines.splice(medicines.findIndex(item => item.id === payload.medicine.id), 1)
        }
    },
    setSecondActiveClient(state, result) {
        if (result.type === 'hr') {
            state.secondActiveClient.healthRecord = result.value
        } else if (result.type === 'prescription') {
            result.value.print_inquiry_date = result.value.created_date ? moment(result.value.created_date).format('LLL') : ''
            state.secondActiveClient.prescription = result.value
        } else if (result.type === 'care') {
            state.secondActiveClient.careList = result.value
        }
    },
    setSecondHR(state, result) {
        state.secondHR = result
    },
    deleteHRFiles(state, payload) {
        let activeHealthRecord = state.healthRecords[payload.patientid]
        let index = activeHealthRecord.formatFiles.findIndex(item => item.id === payload.id)
        if (index !== -1) {
            activeHealthRecord.formatFiles.splice(index, 1)
        }
    },
    setCurrentHRCareInfo(state, result) {
        state.currentHRCareInfo = result
        state.currentHRCareInfo.print_date = state.currentHRCareInfo.created_date ? `${moment(state.currentHRCareInfo.created_date).calendar()} - ` : ''
    },
    setTreatmentLogDetail(state, result) {
        state.treatmentDetailInfo = result
    },
    setCurrentDateSchedule(state, result) {
        result.forEach(item => {
            item.checked = false
            item.showEditLimit = false
            if (item.start_time) {
                let startHour = moment(new Date(item.start_time)).format('HH:mm')
                let endHour = moment(new Date(item.end_time)).format('HH:mm')
                item.timeInterval = `${startHour}-${endHour}`
            }
        })
        state.schedules = result
        state.originSchedules = result
    },
    checkTime(state, payload) {
        let time = (new Date(payload.checkedDate)).setHours(12)
        time = new Date(time)
        state.schedules.forEach(item => {
            if (payload.type === 'day' && item.limit) item.checked = true
            else if (payload.type === 'morning' && new Date(item.end_time) <= time && item.limit) item.checked = true
            else if (payload.type === 'afternoon' && new Date(item.end_time) > time && item.limit) item.checked = true
            else item.checked = false
        })
    },
    checkTimeItem(state, id) {
        state.schedules.some(item => {
            if (item.id === id) {
                item.checked = !item.checked
                return true
            }
        })
    },
    checkAllItem(state) {
        let check = true
        let index = state.schedules.findIndex(item => item.checked)
        if (index !== -1) check = false
        state.schedules.forEach(item => {
            if (item.limit) item.checked = check
        })
    },
    setCurrentMonthSchedule(state, result) {
        state.unScheduledDate = result
    },
    updateCurrentDateSchedule(state, result) {
        let ids = result.ids || []
        state.schedules.forEach(item => {
            item.checked = false
            if (ids.includes(item.id)) item.limit = result.limit
        })
    },
    updateSchedule(state, payload) {
        if (payload.field === 'showEditLimit') {
            state.schedules.forEach(item => {
                if (item.id === payload.id) item[payload.field] = payload.value
                else item[payload.field] = false
            })
        } else {
            let item = state.schedules.find(element => element.id === payload.id)
            item[payload.field] = payload.value
        }
    },
    resetCurrentDateSchedule(state, payload) {
        let newSubscribes = []
        let currentDate = new Date(payload.checkedDate)
        let { startTime, endTime } = payload.time
        let [startTimeHour, startTimeMinute] = startTime.split(':')
        let [endTimeHour, endTimeMinute] = endTime.split(':')
        let minute
        let item_start = startTime, item_end = startTimeMinute < 30 ? `${(Number(startTimeHour) + 1)}:00` : startTimeMinute == 30 ? `${(Number(startTimeHour) + 1)}:30` : `${(Number(startTimeHour) + 2)}:00`
        const oneHourTime = 3600000
        while ((startTimeHour < endTimeHour) || (endTimeMinute > 0 && startTimeHour <= endTimeHour)) {
            let start_time = moment(currentDate).format(`YYYY-MM-DD ${item_start}:00`)
            let end_time = moment(currentDate).format(`YYYY-MM-DD ${item_end}:00`)
            newSubscribes.push({
                start_time: start_time,
                end_time: end_time,
                limit: Math.ceil((new Date(end_time) - new Date(start_time)) / oneHourTime * 15),
                status: 'Planed',
                hospitalid: payload.hospitalid
            })
            item_start = item_end
            startTimeHour = Number(item_end.split(':')[0]) + 1
            minute = item_end.split(':')[1]
            item_end = `${startTimeHour}:${minute}`

        }

        if (item_start != endTime) {
            let start_time = moment(currentDate).format(`YYYY-MM-DD ${item_start}:00`)
            let end_time = moment(currentDate).format(`YYYY-MM-DD ${endTime}:00`)
            newSubscribes.push({
                start_time: start_time,
                end_time: end_time,
                limit: Math.ceil((new Date(end_time) - new Date(start_time)) / oneHourTime * 15),
                status: 'Planed',
                hospitalid: payload.hospitalid
            })
        }

        if (payload.rest) {
            let index = newSubscribes.findIndex(item => new Date(item.start_time).getHours() === 12)
            index != -1 && newSubscribes.splice(index, 1)
        }

        state.schedules = [...newSubscribes]
    },
    resetSchedule(state) {
        state.schedules = state.originSchedules
    },
    emptyOriginalSchedule(state) {
        state.originSchedules = []
    }
}

const actions = {
    async upsertInquiryItem({ state, commit }, payload) {
        let questions = state.inquiryForms[payload.userid]
        if (!questions.changeQuestions.length) {
            Message({
                message: '暂无修改',
                type: 'warning'
            })
            return
        }
        let inquiryItems = questions.changeQuestions
        let deleteIds = [], changeItems = [], res, delRes
        inquiryItems.forEach(item => {
            if (item.id && !item.answer) {
                deleteIds.push(item.id)
            } else {
                changeItems.push(item)
            }
        })
        res = await API.upsertInquiryItem(changeItems)
        delRes = await API.deleteInquiryItem(deleteIds)
        if (res.code === 0 || delRes.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1 && delRes.code === 1) {
            //Force refresh data once all inquiry items are saved successfully
            let res = await API.getInquiryQuestion(payload.userid, payload.inquiryid)
            if (res.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (res.code === 1) {
                commit('addInquiryForm', { inquiryForms: res.result, forceRefresh: true })
            }
            Message({
                message: '问卷更新成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    },
    async getInquiryForm({ state, commit }, payload) {
        if (!state.inquiryForms.hasOwnProperty(payload.clientid) || payload.isUpdate) {
            if (payload.inquiryid) {
                let detailRes = await API.getInquiryDetail(payload.inquiryid)
                if (detailRes.status === 200) {
                    commit('setCurrentInquiry', detailRes.result[0])
                    let res = await API.getInquiryQuestion(payload.clientid, payload.inquiryid)
                    if (res.code === 0) {
                        localStorage.removeItem('Koa:token')
                        window.location.href = '/login'
                    } else if (res.code === 1) {
                        commit('addInquiryForm', { inquiryForms: res.result, forceRefresh: true })
                    } else {
                        console.log(res.err)
                    }
                }
            }
        }
    },
    async getHealthRecord({ state, commit, rootState }, payload) {
        if (!state.healthRecords.hasOwnProperty(payload.clientid) || payload.isUpdate) {
            let res = await API.getHealthRecord(payload.health_recordid)
            let prescriptionRes = await API.getPrescriptionsByHealthRecordId(payload.health_recordid)
            if (prescriptionRes.status == 200) {
                payload['result'] = prescriptionRes.result
                commit('addPrescriptions', payload)
            }
            if (res.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (res.code === 1) {
                let hr = res.result[0]
                if (hr.related_id) {
                    let careRes = await API.getRelatedCareInfo({ health_recordid: hr.related_id })
                    if (careRes.code === 1 && careRes.result.length) {
                        commit('setCurrentHRCareInfo', careRes.result[0])
                    }
                } else {
                    commit('setCurrentHRCareInfo', {})
                }
                let processItems = []
                let types = []
                if (hr.clinical_process && hr.clinical_process.length > 0) {
                    hr.clinical_process.split('\n').forEach(item => {
                        if (item && item.trim().length > 0) {
                            let category = item.split(' ')[0]
                            let detail = {}
                            detail.name = item.split('.')[0].trim().split(' ')[1]
                            let regUnit = /\[(.*?)\]/g, regNumber = /\{(.*?)\}/g, units = [], result = null, numberUnits = []
                            do {
                                result = regUnit.exec(item)
                                result && units.push(result[1])
                            } while (result)
                            do {
                                result = regNumber.exec(item)
                                result && numberUnits.push(result[1])
                            } while (result)
                            detail.id = numberUnits[0]
                            detail.sku = numberUnits[1]
                            detail.unit = numberUnits[2]
                            detail.unit_type = units[0]
                            if (category === '普通药品') {
                                detail.frequency = Number(numberUnits[3])
                                detail.per_quantity = numberUnits[4]
                                detail.per_unit = units[1]
                                detail.price = Number(numberUnits[5]),
                                    detail.delivery = units[2]
                            } else if (category === '沃爱贴') {
                                detail.per_quantity = units[1] ? Number(units[1]) : ''
                                detail.frequency = Number(units[2])
                                detail.delivery = units[3]
                            }
                            detail.new_per_unit = ''
                            processItems.push(Object.assign(detail, { category: category }))
                            types.push(category)
                        }
                    })
                }
                hr.medicines = []
                let ages = hr.age.toFixed(4).split('.') // e.g., 3.1207 表示 3岁12月07天
                hr.familyMember = {
                    id: hr.family_memberid,
                    hrid: hr.id,
                    userid: hr.patientid,
                    name: hr.name,
                    age: hr.age,
                    gender: hr.gender,
                    weight: hr.weight,
                    address: hr.patient_address,
                    phone: hr.patient_phone,
                    y: parseInt(ages[0]),
                    m: parseInt(ages[1].substring(0, 2)),
                    d: parseInt(ages[1].substring(2, 4)),
                    old_phone: hr.patient_phone,
                    inquiry_date: hr.inquiry_date
                }
                hr.selectedTypes = [...new Set(types)]
                hr.medicines = processItems
                commit('setSecondActiveClient', { type: 'hr', value: hr, template: rootState.index.medicines.find(item => item.id === hr.hr_templateid) })
                if (payload.secondType !== 'sub') {
                    commit('addHealthRecord', hr)
                    commit('setSecondHR', hr)
                }
            } else {
                console.log(res.err)
            }
        } else {
            commit('setSecondHR', state.healthRecords[payload.clientid])
        }
    },
    async getSubscribes({ commit }, payload) {
        let res = await API.getSubscribes(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('addSubscribes', { subscribes: res.result, userid: payload.userid })
        } else {
            console.log(res.err)
        }
    },
    async updateHealthRecordTemplate({ commit }, payload) {
        let res = await API.updateHealthRecordTemplate(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('changeHeathRecord')
        } else {
            console.log(res.err)
        }
    },
    async updateHealthRecord({ getters }) {
        try {
            let activeHealthRecord = getters.activeHealthRecord, isValidated = -1
            if (activeHealthRecord) {
                activeHealthRecord.treatment_plan = ''
                if (activeHealthRecord.files instanceof Array) {
                    let index = 0
                    for (let item of activeHealthRecord.files) {
                        if (!item) activeHealthRecord.files.splice(index)
                        index++
                    }
                }
                activeHealthRecord.files = activeHealthRecord.files ? activeHealthRecord.files.join(',') : ''
                if (isValidated < 0) {
                    let res = await API.updateHealthRecord(activeHealthRecord)
                    activeHealthRecord.files = activeHealthRecord.files.split(',')
                    if (res.code === 0) {
                        localStorage.removeItem('Koa:token')
                        window.location.href = '/login'
                    } else if (res.code === 2) {
                        console.log(res.err)
                    }
                }
            }
        } catch (e) {
            alert(`更新病历失败${e.toString()}`)
        }
    },
    async createCareRecord({ rootState, getters, commit }, payload) {
        let res = await API.upsertCareRecord({ health_recordid: getters.activeHealthRecord.id, patientid: getters.activeHealthRecord.patientid, doctorid: rootState.index.userInfo.doctorid })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            let records = { result: res.result, patientid: getters.activeHealthRecord.patientid }
            if (payload)
                Object.assign(records, { type: payload })
            commit('addCareRecords', records)
        } else {
            console.log(res.err)
        }
    },
    async getPrescriptions({ state, commit }, payload) {
        if (!state.patientid2prescriptions.hasOwnProperty(payload.clientid) || payload.isUpdate) {
            let res = await API.getPrescriptionsByHealthRecordId(payload.health_recordid)
            if (res.status == 200) {
                payload['result'] = res.result
                commit('setSecondActiveClient', { type: 'prescription', value: res.result.length ? res.result[0] : [] })
                if (payload !== 'sub') {
                    commit('addPrescriptions', payload)
                }
            }
        }
    },
    async createPrescription({ getters, rootState, commit, dispatch }) {
        let activeHealthRecord = getters.activeHealthRecord
        let selectedPrescription = getters.selectedPrescription
        let prescription = {
            health_recordid: activeHealthRecord.id,
            patientid: activeHealthRecord.patientid,
            family_memberid: activeHealthRecord.family_memberid,
            doctorid: rootState.index.userInfo.doctorid,
            hospitalid: rootState.index.userInfo.hospitalid,
            allergic_history: selectedPrescription.allergic_history ? selectedPrescription.allergic_history : activeHealthRecord.allergic_history,
            clinical_diagnose: activeHealthRecord.clinical_diagnose,
            status: 'Draft',
            department: activeHealthRecord.department,
            patient_name: activeHealthRecord.name,
            patient_age: activeHealthRecord.age,
            patient_gender: activeHealthRecord.gender,
            patient_weight: activeHealthRecord.weight,
            patient_birthday: activeHealthRecord.patient_birthday,
            patient_phone: activeHealthRecord.patient_phone,
            patient_address: activeHealthRecord.patient_address,
            doctor_name: selectedPrescription.doctor_name,
            fee_type: selectedPrescription.fee_type,
            approval_doctor_name: selectedPrescription.approval_doctor_name,
            allocation_doctor_name: selectedPrescription.allocation_doctor_name,
            dispensing_doctor_name: selectedPrescription.dispensing_doctor_name,
            treatment_fee: selectedPrescription.treatment_fee,
            medicine_fee: selectedPrescription.medicine_fee ? selectedPrescription.medicine_fee : 0,
            comment: selectedPrescription.comment,
            registration_fee: selectedPrescription.registration_fee,
            pharmacist_name: selectedPrescription.pharmacist_name,
            amount: (parseFloat(selectedPrescription.medicine_fee ? selectedPrescription.medicine_fee : 0) + parseFloat(selectedPrescription.treatment_fee ? selectedPrescription.treatment_fee : 0) + parseFloat(selectedPrescription.registration_fee ? selectedPrescription.registration_fee : 0)).toFixed(2)
        }

        let res = await API.createPrescription(prescription)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            dispatch('updatePrescription', {
                data: res.result[0],
                saveType: 'save'
            })
            commit('updatePrescriptionState', { health_recordid: res.result[0].health_recordid, field: 'id', value: res.result[0].id })
            commit('updatePrescriptionState', { health_recordid: res.result[0].health_recordid, field: 'status', value: res.result[0].status })
        } else {
            console.log(res.err)
        }
    },
    async updatePrescription({ getters, commit, dispatch }, payload) {
        let prescription = payload.data
        let activeHealthRecord = getters.activeHealthRecord, prescriptionItems = [], isValidated = -1
        prescription.amount = parseFloat(prescription.medicine_fee ? prescription.medicine_fee : 0) + parseFloat(prescription.treatment_fee ? prescription.treatment_fee : 0) + parseFloat(prescription.registration_fee ? prescription.registration_fee : 0)
        if (payload.saveType === 'submit' && (!prescription.amount || !activeHealthRecord.doctor_signature_url)) {
            Notification({
                message: '请确认金额和签名',
                type: 'warning',
                position: 'bottom-right',
                offset: 100
            })
            return
        }
        let flag = true
        if (activeHealthRecord) {
            activeHealthRecord.clinical_process = ''
            prescription.prescription = 0
            activeHealthRecord.medicines.forEach(medicine => {
                if (medicine.category === '普通药品') {
                    if (medicine.frequency <= 0 || medicine.per_quantity <= 0 || medicine.unit <= 0) isValidated = 2
                } else if (medicine.category === '沃爱贴' || medicine.category === '医疗器械') {
                    if (medicine.unit <= 0) isValidated = 1
                }
                if (flag || isValidated < 0) {
                    console.log('into block...')
                    prescriptionItems.push({
                        prescriptionid: prescription.id,
                        medicineid: medicine.id,
                        medicine_name: medicine.name,
                        sku: medicine.sku,
                        unit: medicine.unit,
                        unit_type: medicine.unit_type,
                        type: medicine.type,
                        frequency: medicine.frequency,
                        per_quantity: medicine.per_quantity,
                        unit_price: medicine.price,
                        per_unit: medicine.new_per_unit ? medicine.new_per_unit : medicine.per_unit
                    })
                    activeHealthRecord.clinical_process += medicine.category + ' ' + medicine.name + '. ' +
                        `{${medicine.id}}` +
                        `{${medicine.sku}}` +
                        `{${medicine.unit}}` +
                        `[${medicine.unit_type}]` +
                        (medicine.category === '普通药品' ? ' 一日 ' + `{${medicine.frequency ? medicine.frequency : ''}}` + ' 次,一次 ' + `{${medicine.per_quantity}}` + ` [${medicine.new_per_unit ? medicine.new_per_unit : medicine.per_unit}] {${medicine.price}} [${medicine.delivery ? medicine.delivery : ''}]` : (medicine.category === '沃爱贴' ? `[${medicine.per_quantity  || ''}][${medicine.frequency ? medicine.frequency : ''}][${medicine.delivery ? medicine.delivery : ''}]` : '')) + '\n'
                }
            })
            if (flag || isValidated < 0) {
                let isSendingPrescription = (payload.saveType == 'submit')

                if (isSendingPrescription) prescription.status = 'Pending'
                activeHealthRecord = { id: activeHealthRecord.id, clinical_process: activeHealthRecord.clinical_process, patientid: activeHealthRecord.patientid }
                let res0 = await API.updateHealthRecord(activeHealthRecord)
                let res1 = await API.updatePrescription(prescription)
                if (prescriptionItems.length > 0 && !isSendingPrescription) {
                    await API.deletePrescriptionItems(prescription.id)
                    await API.updatePrescriptionItems(prescriptionItems)
                }

                if (res0.code == 1 && res1.code === 1) {
                    if (isSendingPrescription) {
                        Notification({
                            message: payload.openid ? '处方单已提交并发送用户' : '确认成功',
                            type: 'success',
                            position: 'bottom-right',
                            offset: 100
                        })
                        if (payload.openid) {
                            dispatch('chat/sendPrescription', {
                                prescriptionid: prescription.id,
                                amount: prescription.amount
                            }, { root: true })
                        }
                    }
                    else {
                        Notification({
                            message: '更新成功',
                            type: 'success',
                            position: 'bottom-right',
                            offset: 100
                        })
                    }
                    commit('chat/removeClientUnSaveStatus', {
                        id: activeHealthRecord.patientid,
                        hrid: activeHealthRecord.id
                    }, { root: true })
                } else {
                    Notification({
                        message: '出错了',
                        type: 'error',
                        position: 'bottom-right',
                        offset: 100
                    })
                }
            }
            else {
                Notification({
                    message: isValidated === 1 ? '临床处置药物剂量和数量必填' : '临床处置药物剂量和数量及药品用法必填',
                    type: 'warning',
                    position: 'bottom-right',
                    offset: 100
                })
            }
        }
    },

    async doctorSign({ commit }, payload) {
        let uploadRes = {}
        if (payload.signType == 'healthRecord') {
            uploadRes = await API.updateHealthRecord(payload)
        } else if (payload.signType == 'prescription')
            uploadRes = await API.updatePrescription(payload)
        if (uploadRes.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (uploadRes.code === 1) {
            Message({
                message: '签名成功',
                type: 'success'
            })
            if (payload.signType == 'prescription') {
                commit('approval/updatePrescription', {
                    id: payload.id,
                    patientid: payload.patientid,
                    field: 'approval_doctor_signature_url',
                    value: payload.approval_doctor_signature_url
                }, { root: true })
            } else if (payload.signType === 'healthRecord') {
                commit('updateActiveHealthRecord', {
                    patientid: payload.patientid,
                    isField: true,
                    field: payload.field,
                    value: payload.field === 'approval_doctor_signature_url' ? payload.approval_doctor_signature_url : payload.doctor_signature_url
                })
            }
        } else {
            console.log(uploadRes.err)
        }
    },

    async createMedicine({ commit }, payload) {
        let res = await API.createMedicine(payload.medicine)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('addMedicineToHealthRecords', {
                medicine: res.result[0]
            })
            commit('index/addMedicine', {
                medicine: res.result[0]
            }, { root: true })
            Message({
                message: '药品添加成功',
                type: 'success'
            })

        } else {
            console.log(res.err)
        }
    },

    async updateSignature({ rootState, getters, commit, dispatch }, payload) {
        if (payload.file) {
            let uploadRes = await API.uploadSignature(payload.file, payload.fileType, rootState.index.userInfo.doctorid)
            if (uploadRes.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (uploadRes.code === 1) {
                let doctor = {
                    id: rootState.index.userInfo.doctorid,
                    e_signature: uploadRes.path
                }
                let res = await API.updateDoctorInfo(doctor)
                if (res.code === 1) {
                    Message({ showClose: true, message: '医生签名上传成功', type: 'success' })
                    commit('index/setDoctorSignature', uploadRes.result, { root: true })
                    let signInfo = {
                        id: payload.signType === 'healthRecord' ? getters.activeHealthRecord.id : rootState.approval.prescription.id,
                        patientid: getters.activeHealthRecord.patientid,
                        signType: payload.signType
                    }
                    if (payload.field && payload.field === 'approval_doctor_signature_url' || payload.signType === 'prescription') {
                        Object.assign(signInfo, {
                            approval_doctor_signature: uploadRes.path,
                            approval_doctor_signature_url: uploadRes.result,
                            field: 'approval_doctor_signature_url'
                        })
                    } else {
                        Object.assign(signInfo, {
                            doctor_signature: uploadRes.path,
                            doctor_signature_url: uploadRes.result,
                            field: 'doctor_signature_url'
                        })
                    }
                    dispatch('doctorSign', signInfo)
                }
            } else {
                console.log(uploadRes.err)
            }
        }
    },
    async getCareRecords({ state, commit }, payload) {
        if (!state.careRecords.hasOwnProperty(payload.patientid) || payload.isUpdate) {
            let res = await API.getCareRecordsByHealthRecordId(payload)
            if (res.code == 1) {
                commit('setSecondActiveClient', { type: 'care', value: res.result })
                if (payload !== 'sub') {
                    commit('addCareRecords', { result: res.result, patientid: payload.patientid })
                }
            }
        }
    },
    async updateCareRecord({ commit }, payload) {
        let careRes, hrRes
        careRes = await API.upsertCareRecord(payload)
        let hr = {
            id: payload.health_recordid,
            last_caring_status: payload.feeling_better
        }
        if (payload.feeling_better === '其他，不要再问我' || payload.feeling_better === '已转诊治疗' || payload.feeling_better === '已康复') {
            Object.assign(hr, { id: payload.health_recordid, status: 'Closed', closed_date: new Date() })
        }
        hrRes = await API.updateHealthRecord(hr)
        if (careRes.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (careRes.code === 1 && hrRes.code === 1) {
            payload.value = 1
            payload.field = 'isConfirmed'
            commit('updateActiveCareRecords', payload)
            Message({
                message: '保存成功',
                type: 'success'
            })
        } else {
            console.log(careRes.err)
        }
    },
    async getLogByHospitalId({ commit, state, rootState }, payload) {
        let res = await API.getLogByHospitalId({ hospitalid: rootState.index.userInfo.hospitalid, startDate: payload.startDate, endDate: payload.endDate, pageIndex: payload.pageIndex, pageSize: state.pageSize })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('loadLogs', res.result)
            commit('switchLogLoading')
        } else {
            console.log(res.err)
        }
    },
    async exportData({ commit, rootState }, payload) {
        let res = await API.exportData({ hospitalid: rootState.index.userInfo.hospitalid, startDate: payload.startDate, endDate: payload.endDate })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('setDownloadUrl', res.result)
        } else {
            console.log(res.err)
        }
    },
    async switchHealthRecordTemplate({ commit, getters, rootState }) {
        let targetTemplate = rootState.index.hrTemplates.find(item => item.id === getters.activeHealthRecord.trackTemplateid)
        let res = await API.switchHealthRecordTemplate({ hr: getters.activeHealthRecord, template: targetTemplate })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1 && res.result.length) {
            commit('setCurrentHealthRecord', Object.assign(res.result[0], { sickness: targetTemplate.sickness }))
            Message({
                message: '切换成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    },
    async updateHRFiles({ commit }, payload) {
        try {
            payload.files.splice(payload.formatFiles.findIndex(item => item.id === payload.id), 1)
            let res = await API.updateHRFiles({ id: payload.hrid, files: payload.files.join(',') })
            if (res.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (res.code === 1) {
                commit('deleteHRFiles', payload)
                Message({
                    message: '删除成功',
                    type: 'success'
                })
            } else {
                console.log(res.err)
            }
        } catch (e) {
            alert(`删除失败 ${e.toString()}`)
        }
    },
    async updateFamilyInfo({ commit }, payload) {
        payload.age = (payload.y * 10000 + payload.m * 100 + payload.d * 1) / 10000
        console.log('payload', payload)
        let res = await API.updateFamilyInfo(payload)
        await API.whetherUpdateUserInfo(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200 && res.result.length) {
            let addRes = { status: 200 }, hrRes = {}
            if (payload.address) {
                addRes = await API.updateAddress({ id: res.result[0].addressid, userid: res.result[0].userid, detail: payload.address })
                if (!res.result[0].addressid) {
                    await API.updateFamilyInfo({ id: payload.id, addressid: addRes?.result[0]?.id })
                }
            }
            if (addRes.status === 200) {
                hrRes = await API.updateHealthRecord({
                    id: payload.hrid,
                    name: payload.name,
                    age: payload.age,
                    gender: payload.gender,
                    weight: payload.weight,
                    patient_phone: payload.phone,
                    patient_address: payload.address,
                    inquiry_date: payload.inquiry_date
                })
            }
            if (hrRes.status === 200 && hrRes.result.length && addRes.status == 200) {
                commit('updateHealthRecordFamilyInfo', hrRes.result[0])
                Message({
                    message: '修改成功',
                    type: 'success'
                })
            } else {
                Message({
                    message: '修改失败',
                    type: 'error'
                })
            }
        } else {
            console.log(res.err)
        }
    },
    async uploadHRPics({ state, commit }, payload) {
        try {
            if (payload.file) {
                let uploadRes = await API.uploadHRPics(payload.file, payload.fileType, payload.hrid)
                if (uploadRes.code === 0) {
                    localStorage.removeItem('Koa:token')
                    window.location.href = '/login'
                } else if (uploadRes.code === 1) {
                    commit('addUploadHRFiles', { fullUrl: uploadRes.result, path: uploadRes.path, patientid: payload.patientid })
                    let files = state.healthRecords[payload.patientid].files
                    let index = 0
                    if (files instanceof Array) {
                        for (let item of files) {
                            if (!item) files.splice(index, 1)
                        }
                        index++
                    }
                    files = files.join(',')
                    let res = await API.updateHealthRecord({ id: payload.hrid, files: files })
                    if (res.status === 200) {
                        Message({ message: '上传成功', type: 'success' })
                    } else {
                        Message({ message: res.err, type: 'error' })
                    }
                } else {
                    console.log(uploadRes.err)
                    Message({ message: uploadRes.err, type: 'error' })
                }
            }
        } catch (e) {
            alert(`上传图片失败 ${e.toString()}`)
        }
    },
    async upsertCare({ commit }, payload) {
        let res = await API.upsertCare(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1 && res.result.length) {
            commit('setCurrentHRCareInfo', res.result[0])
            Message({
                message: '操作成功',
                type: 'success'
            })
            // commit('switchLogLoading')
        } else {
            console.log(res.err)
        }
    },
    async changeHRTemplatesSequence({ rootState }) {
        let changeSequenceTemplates = []
        let index = 1
        rootState.index.hrTemplates.forEach(item => {
            changeSequenceTemplates.push({ id: item.id, name: item.name, sequence: (index++) * 10 })
        })
        let res = await API.changeHRTemplatesSequence(changeSequenceTemplates)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            Message({
                message: '操作成功',
                type: 'success'
            })
            // commit('switchLogLoading')
        } else {
            console.log(res.err)
        }
    },
    async getTreatmentLogDetail({ commit }, payload) {
        let res = await API.getTreatmentLogDetail(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1 && res.result.length) {
            commit('setTreatmentLogDetail', res.result[0])
        } else {
            console.log(res.err)
        }
    },
    async getCurrentDateSchedule({ commit, rootState }, payload) {
        let res = await API.getCurrentDateSchedule({ hospitalid: rootState.index.userInfo.hospitalid, date: payload })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setCurrentDateSchedule', res.result)
        } else {
            console.log(res.err)
        }
    },
    async updateScheduleLimit({ commit }, payload) {
        let res = await API.updateHospitalScheduleLimit({ ids: payload.changedSchedules, limit: payload.limit })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            Message({
                message: '操作成功',
                type: 'success'
            })
            commit('updateCurrentDateSchedule', { ids: payload.changedSchedules, limit: payload.limit })
        } else {
            console.log(res.err)
        }
    },
    async getCurrentMonthSchedule({ commit }, payload) {
        let res = await API.getCurrentMonthSchedule({ date: payload })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setCurrentMonthSchedule', res.result)
        } else {
            console.log(res.err)
        }
    },
    async resetHospitalSchedule({ state, commit }) {
        let originalIds = state.originSchedules.map(item => item.id)
        let res = await API.resetHospitalSchedule({ originalIds: originalIds, schedules: state.schedules })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('emptyOriginalSchedule')
            if (res.result === 200) {
                Message({
                    message: '操作成功',
                    type: 'success'
                })
            } else {
                Message({
                    message: '操作失败，已有病人在该日进行预约挂号',
                    type: 'error'
                })
            }
        } else {
            console.log(res.err)
        }
    },
    async generateHospitalSchedule({ rootState }, payload) {
        let hospitalid = rootState.index.userInfo.hospitalid
        let res = await API.generateHospitalSchedule({ hospitalid, startDate: payload.startDate, endDate: payload.endDate })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            if (res.result) {
                Message({
                    message: '生成成功',
                    type: 'success'
                })
            } else {
                Message({
                    message: '生成失败，已有病人在该时段中预约挂号',
                    type: 'error'
                })
            }
        } else {
            console.log(res.err)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
