function formatNumber (n) {
	const str = n.toString()
	return str[1] ? str : `0${str}`
}

export function formatDateTime (date) {
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()

	const hour = date.getHours()
	const minute = date.getMinutes()
	const second = date.getSeconds()

	const t1 = [year, month, day].map(formatNumber).join('-')
	const t2 = [hour, minute, second].map(formatNumber).join(':')

	return `${t1} ${t2}`
}

export function formatDateTime2 (date) {
	const month = date.getMonth() + 1
	const day = date.getDate()

	const hour = date.getHours()
	const minute = date.getMinutes()

	const t1 = [month, day].map(formatNumber).join('-')
	const t2 = [hour, minute].map(formatNumber).join(':')

	return `${t1} ${t2}`
}

export function formatDate2HM (date) {
	const hour = date.getHours()
	const minute = date.getMinutes()

	const t = [hour, minute].map(formatNumber).join(':')

	return `${t}`
}

export function formatTime (date) {
	const hour = date.getHours()
	const minute = date.getMinutes()
	const t = [hour, minute].map(formatNumber).join(':')

	return `${t}`
}

export function formatDate (date) {
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()

	const t1 = [year, month, day].map(formatNumber).join('-')
	return `${t1}`
}

export function addDays (date, days) {
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()

	let targetDate = new Date(`${year}/${month}/${day}`)
	let dateStamp = targetDate.setMilliseconds(targetDate.getMilliseconds() + (days * 24 * 60 * 60 * 1000))
	return new Date(dateStamp)
}

export function isBlank (str) {
	return str === undefined || str.length === 0 || str.trim().length === 0
}

export default {
	formatNumber
}
