import API from '@/utils/api'
import moment from "moment"
import { Message } from 'element-ui'
moment.locale("zh-cn")
const state = {
    prescriptions: [], //list
    prescriptionItems: {}, // medicine list 
    currentPage: 0,
    totalNumber: 0,
    statusOptions: {},
    medicineStr: '',
    prescription: {},
    pageSize: 15,
    currentPrescriptionId: '',
    showDetail: false,
    showList: true,
    approvalHeader: "处方单列表",
    pageNumber: null,
    loading: false,
    prescriptionStatus: {
        value: '',
        class: ''
    },
    prescriptionStatusMap: (() => {
        let map = new Map()
        map.set('Pending', ['待审核', ''])
        map.set('Paid', ['待审核', ''])
        map.set('Approved', ['已通过', 'success'])
        map.set('Delivered', ['已取药', 'warning'])
        map.set('Canceled', ['已取消', 'danger'])
        return map
    })()
}
// getters
const getters = {}

//mutations
const mutations = {
    setPrescriptions (state, res) {
        res.list.forEach(element => {
            element.status = {
                value: state.prescriptionStatusMap.get(element.status) ? state.prescriptionStatusMap.get(element.status)[0] : '',
                class: state.prescriptionStatusMap.get(element.status) ? state.prescriptionStatusMap.get(element.status)[1] : ''
            }
            element.created_date = moment(element.created_date).format('LLL')
        })
        state.prescriptions = [...state.prescriptions, ...res.list]
        state.totalNumber = res.totalNumber
        state.pageNumber = res.pageNumber
    },
    setPrescriptionDetail (state, res) {
        // list
        state.prescriptionItems = res.list
        // medicine info 
        let str = ''
        res.list.forEach((item, index) => { str += index + 1 + '.' + item.medicine_name + '*' + item.qty + '\n' })
        state.medicineStr = str
        if (!res.prescription.length) return
        // prescription
        let prescription = res.prescription[0]
        prescription.created_date = moment(prescription.created_date).format('LLL')
        if (prescription.age) {
            let ages = prescription.age.toFixed(4).split('.') // e.g., 3.1207 表示 3岁12月07天
            let ageYear = parseInt(ages[0]),
                ageMonths = parseInt(ages[1].substring(0, 2)),
                ageDays = parseInt(ages[1].substring(2, 4))
            prescription['ageText'] = (ageYear > 0 ? ageYear + '岁' : (ageMonths > 0 ? ageMonths + '月' : (ageDays > 0 ? ageDays + '天' : '')))
        }
        state.prescription = Object.assign({}, prescription)
    },
    setPrescriptionStatus (state, res) {
        state.prescriptionStatus = { value: state.prescriptionStatusMap.get(res.status)[0], class: state.prescriptionStatusMap.get(res.status)[1] }
    },
    setPrescriptionListStatus (state, res) {
        state.prescriptions.some((prescription) => {
            if (prescription.id === res.id) {
                prescription.status = {
                    value: state.prescriptionStatusMap.get(res.status) ? state.prescriptionStatusMap.get(res.status)[0] : '',
                    class: state.prescriptionStatusMap.get(res.status) ? state.prescriptionStatusMap.get(res.status)[1] : ''
                }
                return true
            }
        })
    },
    setCurrentPage (state, index) {
        state.currentPage = index
    },
    setPrescriptionStatusOptions (state, res) {
        let options = []
        options.push({ label: '显示所有', value: '' })
        for (let item of res) {
            let label = state.prescriptionStatusMap.get(item.status) ? state.prescriptionStatusMap.get(item.status)[0] : ''
            let isNewOption = true
            options.some(option => {
                if (option.label === label) {
                    option.value = [option.value, item.status]
                    isNewOption = false
                    return true
                }
            })
            if (isNewOption) {
                options.push({ label: label, value: item.status })
            }
        }
        state.statusOptions = options
    },
    setCurrentPrescriptionId (state, id) {
        state.currentPrescriptionId = id
    },
    emptyPrescriptions (state) {
        state.prescriptions = []
    },
    switchPrescriptionLoading (state) {
        state.loading = !state.loading
    },
    emptyPageNumber (state) {
        state.pageNumber = null
    },
    updatePrescription (state, payload) {
        let prescription = state.prescription
        prescription[payload.field] = payload.value
        state.prescription = JSON.parse(JSON.stringify(prescription))
    }
}

//actions
const actions = {
    async getPrescriptionStatusOptions ({ commit, rootState }) {
        let res = await API.getPrescriptionStatusOptions(rootState.index.userInfo.hospitalid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        }
        if (res.code === 1) {
            commit('setPrescriptionStatusOptions', res.result)
        } else {
            console.log(res.err)
        }
    },
    async getPrescriptions ({ commit, state, rootState }, searchInfo) {
        let res = await API.getPrescriptions(rootState.index.userInfo.hospitalid, searchInfo, state.pageSize)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        }
        if (res.code === 1) {
            commit('setPrescriptions', res.result)
            commit('switchPrescriptionLoading')
        } else {
            console.log(res.err)
        }
    },
    async getPrescriptionItems ({ commit, state }) {
        if (!state.currentPrescriptionId) return
        let res = await API.getPrescriptionItems(state.currentPrescriptionId)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        }
        if (res.code === 1) {
            commit('setPrescriptionDetail', res.result)
            if (res.result.prescription.length) {
                commit('setPrescriptionStatus', res.result.prescription[0])
            }
        } else {
            console.log(res.err)
        }
    },
    async updatePrescriptionStatus ({ commit, state, rootState }, payload) {
        if (payload.status === 'Delivered')
            await API.updateHealthRecord({
                id: payload.health_recordid,
                status: 'Caring',
                caring_date: new Date()
            })
        let res = await API.updatePrescriptionStatus(state.currentPrescriptionId, payload, rootState.index.userInfo.doctorid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        }
        if (res.code === 1) {
            let message = ''
            if (payload.status === 'Approved')
                message = '处方单审批通过'
            else if (payload.status === 'Canceled')
                message = '操作成功'
            else if (payload.status === 'Delivered')
                message = '已取药'
            Message({
                message: message,
                type: 'success'
            })
            commit('setPrescriptionStatus', res.result[0])
            commit('setPrescriptionListStatus', res.result[0])
        } else {
            console.log(res.err)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}