import axios from 'axios'

const instance = axios.create({
    timeout: 30000
})

class OSS {
    uploadFileToOss = (path, file, fileType, ossInfo, isBase64) => {
        let aliyunFileKey = `${path}/${ossInfo.key}.${fileType}`
        let data = new FormData()
        data.append('policy', ossInfo.policy)
        data.append('OSSAccessKeyId', ossInfo.OSSAccessKeyId)
        data.append('success_action_status', '200')
        data.append('signature', ossInfo.signature)
        data.append('key', aliyunFileKey)
        if (isBase64) {
            data.append('file', this.dataURLtoBlob(file))
        } else {
            data.append('file', file)
        }
        return instance.post(`${ossInfo.host}`, data).then(res => {
            if (res.status === 200) {
                return {
                    key: aliyunFileKey,
                    status: 200
                }
            }
        }).catch(err => {
            return {
                status: 400,
                error: err
            }
        })
    }

    // dataURL to blob
    dataURLtoBlob = (dataurl) => {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
}

export default new OSS()