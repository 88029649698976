// initial state
import moment from 'moment'
moment.locale('zh-cn')
import API from '@/utils/api'
import { Message } from 'element-ui'

const options = { Inquiring: '问诊', Caring: '随诊', All: '所有人' }

const state = {
    allClients: [],
    activeTabIndex: 999,
    selectedKey: 'Inquiring',
    onlineClientIds: [],
    activeClientHealthRecords: [],
    offlinePatient: {},
    activeName: 'healthRecord',
    familyMembers: [],
    systemUsers: [],
    newFamilyMember: {},
    hrWithUserInfo: {},
    mergeUsers: [],
    candidateUsers: [],
    isUpdateClient: false
}

// getters
const getters = {
    hasActiveClient (state) {
        return state.allClients.filter(client => client.active === true).length > 0
    },
    activeClient (state, getters) {
        if (getters.hasActiveClient) {
            return state.allClients.filter(client => client.active === true)[0]
        }
        return null
    },
    hasActiveMessages (state, getters) {
        return getters.hasActiveClient && getters.activeClient.messages && getters.activeClient.messages.length > 0
    },
    activeMessages (state, getters) {
        if (getters.hasActiveMessages) {
            return state.allClients.filter(client => client.active === true)[0].messages
        }
        return []
    },
    activeImagePreviewList (state) {
        let list = []
        if (getters.hasActiveMessages) {
            let msgs = state.allClients.filter(client => client.active === true)[0].messages
            msgs.forEach(msg => {
                if (msg.type === 'Image') {
                    list.push(msg.url)
                }
            })
            return list
        }
        return []
    },
    allUnReadMessagesCount (state) {
        let count = 0
        state.allClients.forEach(client => {
            if (typeof (client.unReadMessageCount) === 'number') {
                count += client.unReadMessageCount
            }
        })
        return count === 0 ? '' : count
    },
    selectedOption (state) {
        return options[state.selectedKey]
    },
    showChatContent (state, getters, rootState) {
        let sideBarOperationList = rootState.index.sideBarOperationList
        if (getters.activeClient) {
            for (let item of sideBarOperationList) {
                if (rootState.index[item]) {
                    return false
                }
            }
            return true
        } else {
            return false
        }
    },
    sideBarActive (state, getters, rootState) {
        let sideBarOperationList = rootState.index.sideBarOperationList
        for (let item of sideBarOperationList) {
            if (rootState.index[item]) {
                return true
            }
        }
        return false
    }
}

//mutations
const mutations = {
    emptyUsers (state) {
        state.allClients = []
    },
    emptyFamilyMembers (state) {
        state.familyMembers = []
    },
    initAllUsers (state, { users, online_patientids, isOnline, type, activeClient }) {
        users.forEach(user => {
            let inList = false
            user.messages = []
            user.unSendMessage = undefined
            user.active = false
            if (isOnline || (online_patientids && online_patientids.includes(user.id))) {
                user.online = true
            } else {
                user.online = false
            }
            user.unReadMessageCount = !user.unReadMessageCount || user.unReadMessageCount === 0 ? '' : user.unReadMessageCount
            if (user.message_sent_date) {
                user.messageFormatSentDate = moment(user.message_sent_date).calendar()
            } else {
                user.messageFormatSentDate = null
            }
            if (user.fm_age) {
                let ages = user.fm_age.toFixed(4).split('.') // e.g., 3.1107 表示 3岁11月07天
                let ageYear = parseInt(ages[0]),
                    ageMonths = parseInt(ages[1].substring(0, 2)),
                    ageDays = parseInt(ages[1].substring(2, 4))
                user.fm_ageText = (ageYear > 0 ? ageYear + '岁' : '') + (ageMonths > 0 ? ageMonths + '个月' : '') + (ageDays > 0 ? ageDays + '天' : '')
            }
            user.unSaveHRs = []
            state.allClients.forEach(client => {
                if (client.id === user.id) {
                    user.active = client.active
                    user.unSendMessage = client.unSendMessage
                    user.messages = client.messages
                    user.openid = user.openid ? user.openid : ''
                    client = Object.assign(client, user)
                    inList = true
                }
                if (activeClient && client.id === activeClient.id) {
                    client.active = true
                }
            })
            if (!inList) {
                if (type === 'add') state.allClients = [user, ...state.allClients]
                else state.allClients = [...state.allClients, user]
            }
        })
    },
    removeUsers (state, removeUserIds) {
        for (let id of removeUserIds) {
            state.allClients.splice(state.allClients.findIndex(client => client.id === id), 1)
        }
    },
    switchActiveUser (state, user) {
        if (state.activeTabIndex === 999) {
            state.activeTabIndex = 0
        }
        if (!user.openid) {
            state.activeName = 'healthRecord'
        }
        state.allClients.filter(client => client.active === true).forEach(client => {
            client.active = false
            client.messages = []
        })
        state.allClients.filter(client => client.id === user.id).forEach(client => {
            client.active = true
            client.unReadMessageCount = ''
        })
    },
    initMessagesToClient (state, payload) {
        state.allClients.filter(client => client.id === payload.clientid).forEach(client => {
            client.unReadMessageCount = ''
            payload.messages.forEach(msg => {
                msg.formatDate = moment(msg.created_date).calendar()
                msg.sending = false
                if (msg.type === 'Prescription') {
                    msg.amount = JSON.parse(msg.message).amount
                } else if (msg.type === 'Inquiry') {
                    msg.status = JSON.parse(msg.message).status
                } else if (msg.type === 'Question') {
                    msg.content = JSON.parse(msg.message)
                    msg.status = msg.content.status
                    msg.question = msg.content.question
                    msg.answer = msg.content.answer
                } else if (msg.type === 'BotText') {
                    msg.reply = JSON.parse(msg.message).reply
                } else if (msg.type === 'Post') {
                    msg.posts = JSON.parse(msg.message)
                }
                if (!msg.has_read && msg.sent_byid === msg.patientid) {
                    client.unReadMessageCount = client.unReadMessageCount === '' ? 1 : client.unReadMessageCount + 1
                }
            })
            client.messages = [...payload.messages]
        })
    },
    pushMessageToClient (state, payload) {
        state.allClients.filter(client => client.id === payload.clientid).forEach(client => {
            let message = payload.message
            message.formatDate = moment(message.created_date).calendar()
            client.messages = [...client.messages, message]
            client.unSendMessage = undefined
            client.message = message.message
            client.type = message.type
            client.message_sent_date = message.created_date
            client.messageFormatSentDate = moment(message.created_date).calendar()
            if (!payload.isActive) {
                client.unReadMessageCount = client.unReadMessageCount === '' ? 1 : client.unReadMessageCount + 1
            }
        })
        state.allClients.sort((a, b) => new Date(b.message_sent_date) - new Date(a.message_sent_date))
    },
    setUnSendMessage (state, message) {
        state.allClients.filter(client => client.active === true).forEach(client => {
            client.unSendMessage = message
        })
    },
    removeOfflineClient (state, payload) {
        state.allClients.filter(client => client.id === payload.patientid).forEach(client => client.online = false)
    },
    markMessageAsReaded (state, payload) {
        state.allClients.filter(client => client.active === true).forEach(client => {
            if (client.messages instanceof Array) {
                client.messages.forEach(msg => {
                    if (msg.id === payload.message.id) {
                        msg.has_read = true
                    }
                })
            }
        })
    },
    markAllMessagesAsReaded (state, payload) {
        state.allClients.filter(client => client.id === payload.sent_byid).forEach(client => {
            if (client.messages instanceof Array) {
                client.messages.forEach(msg => {
                    msg.has_read = true
                })
            }
        })
    },
    markAllInquiryMessagesAsConfirmed (state, payload) {
        state.allClients.filter(client => client.id === payload.patientid).forEach(client => {
            if (client.messages instanceof Array) {
                client.messages.filter(msg => msg.health_recordid === payload.health_recordid && msg.type === 'Inquiry').forEach(msg => {
                    msg.status = 'Success'
                })
            }
        })
    },
    setActiveTabIndex (state, { index }) {
        state.activeTabIndex = index
    },
    setSelectedKey (state, key) {
        state.selectedKey = key
    },
    markInquiryItemAsConfirmed (state, payload) {
        let message = JSON.parse(payload.message)
        state.allClients.filter(client => client.id === payload.patientid).forEach(client => {
            if (client.messages instanceof Array) {
                client.messages.filter(msg => msg.id === message.id).forEach(msg => {
                    msg.status = 'Success'
                    msg.answer = message.message.answer
                })
            }
        })
    },
    addOnlineClientIds (state, clientIds) {
        state.onlineClientIds = [...state.onlineClientIds, ...clientIds]
    },
    removeOfflineClientIds (state, clientIds) {
        state.onlineClientIds = state.onlineClientIds.filter(id => !clientIds.includes(id))
    },
    setActiveClientHealthRecords (state, hrs) {
        hrs.forEach(hr => {
            hr.date = moment(hr.created_date).format('yyyy 年 MM 月 DD 日')
            hr.display_text = `${hr.name}${hr.sickness ? ' - ' + hr.sickness : ''}${hr.first_subsequent_visit ? ' - ' + hr.first_subsequent_visit : ''}${hr.inquiry_date ? ' - ' + moment(hr.inquiry_date).format('YYYY-MM-DD') : ''}`
            hr.display_text_care = `${hr.sickness}${hr.first_subsequent_visit ? ' - ' + hr.first_subsequent_visit : ''}${hr.inquiry_date ? ' - ' + moment(hr.inquiry_date).format('MM-DD') : ''}`
        })
        state.activeClientHealthRecords = hrs
    },
    updateActiveClientHealthRecords (state, hr) {
        hr.display_text = `${hr.name}${hr.sickness ? ' - ' + hr.sickness : ''}${hr.first_subsequent_visit ? ' - ' + hr.first_subsequent_visit : ''}${hr.inquiry_date ? ' - ' + moment(hr.inquiry_date).format('YYYY-MM-DD') : ''}`
        hr.display_text_care = `${hr.sickness}${hr.first_subsequent_visit ? ' - ' + hr.first_subsequent_visit : ''}${hr.inquiry_date ? ' - ' + moment(hr.inquiry_date).format('MM-DD') : ''}`
        state.activeClientHealthRecords.splice(state.activeClientHealthRecords.findIndex(item => item.id === hr.id), 1, hr)
    },
    removeActiveClientHealthRecords (state, id) {
        state.activeClientHealthRecords.splice(state.activeClientHealthRecords.findIndex(item => item.id === id), 1)
    },
    addActiveClientHealthRecords (state, hr) {
        hr.display_text = `${hr.name}${hr.sickness ? ' - ' + hr.sickness : ''}${hr.first_subsequent_visit ? ' - ' + hr.first_subsequent_visit : ''}${hr.inquiry_date ? ' - ' + moment(hr.inquiry_date).format('YYYY-MM-DD') : ''}`
        hr.display_text_care = `${hr.sickness}${hr.first_subsequent_visit ? ' - ' + hr.first_subsequent_visit : ''}${hr.inquiry_date ? ' - ' + moment(hr.inquiry_date).format('MM-DD') : ''}`
        state.activeClientHealthRecords.unshift(hr)
    },
    setActiveClientHr (state, { id, inquiryid, name, age, gender }) {
        state.allClients.filter(client => client.active === true).forEach(client => {
            client.health_recordid = id
            client.inquiryid = inquiryid
            client.fm_name = name
            if (age) {
                let ages = age.toFixed(4).split('.') // e.g., 3.1107 表示 3岁11月07天
                let ageYear = parseInt(ages[0]),
                    ageMonths = parseInt(ages[1].substring(0, 2)),
                    ageDays = parseInt(ages[1].substring(2, 4))
                client.fm_ageText = (ageYear > 0 ? ageYear + '岁' : '') + (ageMonths > 0 ? ageMonths + '个月' : '') + (ageDays > 0 ? ageDays + '天' : '')
            }
            client.gender = gender
        })
    },
    setOfflinePatient (state, result) {
        state.offlinePatient = result
    },
    setActiveName (state, payload) {
        if (payload.name) {
            state.activeName = payload.name
        }
        else {
            state.activeName = 'healthRecord'

        }
    },
    setFamilyMembers (state, result) {
        if (result.length) {
            result.push({
                id: null,
                name: '新建病人'
            })
        }
        state.familyMembers = result
    },
    setSystemUsers (state, result) {
        state.systemUsers = result.length ? result : []
    },
    setNewFamilyMember (state, result) {
        state.newFamilyMember = result.length ? result[0] : {}
    },
    setHrWithUserInfo (state, result) {
        state.hrWithUserInfo = result
    },
    setMergeUsers (state, result) {
        result.forEach(item => {
            let ages = item.age.toFixed(4).split('.')
            let ageYear = parseInt(ages[0]),
                ageMonths = parseInt(ages[1].substring(0, 2)),
                ageDays = parseInt(ages[1].substring(2, 4))
            ageYear = ageYear > 0 ? ageYear + '岁' : ''
            ageMonths = ageMonths > 0 ? ageMonths + '个月' : ''
            ageDays = ageDays > 0 ? ageDays + '天' : ''
            item['ageText'] = ageYear + ageMonths + ageDays
        })
        state.mergeUsers = result
    },
    removeMergeUsers (state, result) {
        state.mergeUsers = state.mergeUsers.filter(user => {
            return user.userid === result
        })
    },
    updateMergeUsers (state, result) {
        if (result.type === 'add') {
            if (state.mergeUsers.findIndex(item => item.id === result.value.id) === -1)
                state.mergeUsers.push(result.value)
        } else {
            state.mergeUsers.splice(state.mergeUsers.findIndex(item => item.id === result.value), 1)
        }
    },
    setCandidateUsers (state, result) {
        state.candidateUsers = result
    },
    changeClientSaveStatus (state, payload) {
        state.allClients.some(client => {
            if (client.id === payload.id) {
                if (!client.unSaveHRs.includes(payload.hrid)) {
                    client.unSaveHRs.push(payload.hrid)
                }
                return true
            }
        })
    },
    removeClientUnSaveStatus (state, payload) {
        state.allClients.some(client => {
            if (client.id === payload.id) {
                let index = client.unSaveHRs.findIndex(item => item === payload.hrid)
                if (index !== -1) {
                    client.unSaveHRs.splice(index, 1)
                }
            }
        })
    },
    setUpdateClient (state, result) {
        state.isUpdateClient = result
    }
}

//actions
const actions = {
    removeOfflineClient ({ commit }, payload) {
        commit('removeOfflineClient', payload)
    },

    async switchUsersByStatus ({ dispatch, state, rootState }, payload) {
        await dispatch('getUsersByHealthRecordStatus', {
            doctorid: rootState.index.userInfo.id,
            patientid: [],
            online_patientids: state.onlineClientIds,
            status: state.selectedKey,
            searchKey: payload ? payload.searchKey : '',
            is_refresh: payload ? payload.is_refresh : false
        })
    },

    async getUsersByHealthRecordStatus ({ state, commit, dispatch, getters }, payload) {
        let res = await API.getUsersByHealthRecordStatus(payload.doctorid, payload.patientid, payload.status, payload.searchKey)
        let activeClient = getters.activeClient
        if (res.code === 0) {
            payload.$router.push('/index')
        }
        if (res.code === 1) {
            commit('emptyUsers')
            commit('inquiry/emptyFormStates', activeClient, { root: true })
            commit('initAllUsers', {
                users: res.result,
                online_patientids: payload.online_patientids,
                activeClient: payload.is_refresh ? '' : activeClient
            })
            if (state.activeTabIndex === 0) {
                await dispatch('openChatTab')
            }
        } else {
            console.error(res.err)
        }
    },

    async addInquiryUser ({ commit, state, rootState, getters, dispatch }, payload) {
        let res = await API.getUsersByHealthRecordStatus(payload.doctorid, payload.patientid, state.selectedKey)
        if (res.code === 0) {
            payload.$router.push('/index')
        }
        if (res.code === 1) {
            commit('initAllUsers', {
                users: res.result,
                isOnline: true,
                type: 'add'
            })
            let user = getters.activeClient
            if (user && user.id === payload.patientid) {
                await dispatch('getMessages', {
                    patientid: user.id,
                    userid: rootState.index.userInfo.id
                })
                if (!payload.isReconnect) {
                    await dispatch('socket/markAllMessagesAsReaded', {
                        recipient: user.id,
                        sent_byid: rootState.index.userInfo.id
                    }, { root: true })
                    await dispatch('inquiry/getHealthRecord', {
                        health_recordid: user.health_recordid,
                        clientid: user.id,
                        isUpdate: true
                    }, { root: true })
                    await dispatch('inquiry/getInquiryForm', {
                        inquiryid: user.inquiryid,
                        clientid: user.id,
                        isUpdate: true
                    }, { root: true })
                    await dispatch('inquiry/getSubscribes', {
                        userid: user.id,
                        isUpdate: true
                    }, { root: true })
                    await dispatch('inquiry/getCareRecords', {
                        health_recordid: user.health_recordid,
                        patientid: user.id,
                        doctorid: rootState.index.userInfo.doctorid,
                        isUpdate: true
                    }, { root: true })
                    await dispatch('getHealthRecordByPatientIdAndStatus')
                }
            }
        } else {
            console.error(res.err)
        }
    },

    async switchActiveUser ({
        commit,
        dispatch,
        rootState
    }, user) {
        if (!user.active) {
            commit('switchActiveUser', user)
            if (user.isUpdate) {
                commit('setUpdateClient', false)
            }
            await dispatch('inquiry/getHealthRecord', {
                health_recordid: user.health_recordid,
                clientid: user.id,
                isUpdate: user.isUpdate
            }, { root: true })
            await dispatch('inquiry/getCareRecords', {
                health_recordid: user.health_recordid,
                patientid: user.id,
                doctorid: rootState.index.userInfo.doctorid,
                isUpdate: user.isUpdate
            }, { root: true })
            await dispatch('inquiry/getSubscribes', {
                userid: user.id,
                isUpdate: true
            }, { root: true })
            await dispatch('getHealthRecordByPatientIdAndStatus')
            if (user.openid) {
                if (user.inquiryid) {
                    await dispatch('inquiry/getInquiryForm', {
                        inquiryid: user.inquiryid,
                        clientid: user.id,
                        isUpdate: user.isUpdate
                    }, { root: true })
                }
                await dispatch('getMessages', {
                    patientid: user.id,
                    userid: rootState.index.userInfo.id,
                    isUpdate: user.isUpdate

                })
                await dispatch('socket/markAllMessagesAsReaded', {
                    recipient: user.id,
                    sent_byid: rootState.index.userInfo.id,
                    isUpdate: user.isUpdate
                }, { root: true })
            }
        }
    },

    async openChatTab ({ dispatch, rootState, getters }) {
        if (getters.activeClient) {
            dispatch('getMessages', {
                patientid: getters.activeClient.id,
                userid: rootState.index.userInfo.id,
            })
            dispatch('socket/markAllMessagesAsReaded', {
                recipient: getters.activeClient.id,
                sent_byid: rootState.index.userInfo.id
            }, { root: true })
        }
    },

    async getMessages ({ commit }, payload) {
        let res = await API.getMessages(payload.patientid, payload.userid)
        if (res.code === 0) {
            payload.$router.push('/index')
        }
        if (res.code === 1) {
            commit('initMessagesToClient', {
                clientid: payload.patientid,
                messages: res.result
            })
        } else {
            console.error(res.err)
        }
    },

    async pushSendMessageToClient ({ commit, rootState, getters, dispatch }) {
        if (typeof (getters.activeClient.unSendMessage) === 'string' && getters.activeClient.unSendMessage.length > 0) {
            let message = {
                type: 'Text',
                health_recordid: getters.activeClient.health_recordid,
                doctor_userid: rootState.index.userInfo.id,
                patientid: getters.activeClient.id,
                sent_by: 'Doctor',
                has_read: false,
                sent_byid: rootState.index.userInfo.id,
                message: getters.activeClient.unSendMessage,
                sending: true
            }
            let res = await API.createMessage(message)
            if (res.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (res.code === 1) {
                message = res.result[0]
                message.sending = false
                message.recipient = getters.activeClient.id
                dispatch('socket/sendMessage', message, {
                    root: true
                })
                commit('pushMessageToClient', {
                    clientid: getters.activeClient.id,
                    message: message,
                    isActive: true,
                    isDoctor: true
                })
            } else {
                console.log(res.err)
            }
        }
    },

    async pushReceiveMessageToClient ({ commit, state, getters, dispatch }, payload) {
        if (getters.activeClient && getters.activeClient.id === payload.message.sent_byid && state.activeTabIndex == 0) {
            let res = await API.updateMessageStatus(payload.message)
            if (res.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (res.code === 1) {
                let msg = res.result[0]
                msg.url = payload.message.url
                commit('pushMessageToClient', {
                    clientid: msg.sent_byid,
                    message: msg,
                    isActive: true,
                    isPatient: true
                })
                dispatch('socket/markMessageAsReaded', msg, { root: true })
            } else {
                console.log(res.err)
            }
        } else {
            commit('pushMessageToClient', {
                clientid: payload.message.sent_byid,
                message: payload.message,
                isActive: false,
                isPatient: true
            })
        }
    },

    async uploadFile ({ commit, rootState, getters, dispatch }, payload) {
        if (payload.file) {
            let prefix = `chat/${getters.activeClient.id}/image`
            let uploadRes = await API.uploadFile(prefix, payload.file, payload.fileType)
            let message = {
                type: 'Image',
                health_recordid: getters.activeClient.health_recordid,
                doctor_userid: rootState.index.userInfo.id,
                patientid: getters.activeClient.id,
                sent_by: 'Doctor',
                has_read: false,
                sent_byid: rootState.index.userInfo.id,
                message: uploadRes.path.replace(prefix + '/', ''),
                sending: true
            }
            let res = await API.createMessage(message)
            if (res.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (res.code === 1) {
                message = res.result[0]
                message.sending = false
                message.recipient = getters.activeClient.id
                message.url = uploadRes.result
                dispatch('socket/sendMessage', message, {
                    root: true
                })
                commit('pushMessageToClient', {
                    clientid: getters.activeClient.id,
                    message: message,
                    isActive: true,
                    isDoctor: true
                })
            } else {
                console.log(res.err)
            }
        }
    },

    async sendPrescription ({ commit, rootState, getters, dispatch }, payload) {
        let msg = {
            prescriptionid: payload.prescriptionid,
            amount: payload.amount
        }
        let message = {
            type: 'Prescription',
            health_recordid: getters.activeClient.health_recordid,
            doctor_userid: rootState.index.userInfo.id,
            patientid: getters.activeClient.id,
            sent_by: 'Doctor',
            has_read: false,
            sent_byid: rootState.index.userInfo.id,
            message: JSON.stringify(msg),
            sending: true
        }
        let res = await API.createMessage(message)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            message = res.result[0]
            message.sending = false
            message.recipient = getters.activeClient.id
            message.amount = payload.amount
            dispatch('socket/sendMessage', message, {
                root: true
            })
            commit('pushMessageToClient', {
                clientid: getters.activeClient.id,
                message: message,
                isActive: true,
                isDoctor: true
            })
        } else {
            console.log(res.err)
        }
    },

    async sendInquiryForm ({ commit, rootState, getters, dispatch }, payload) {
        let msg = {
            inquiryid: payload.inquiryid,
            status: 'Pending'
        }
        let message = {
            type: 'Inquiry',
            health_recordid: getters.activeClient.health_recordid,
            doctor_userid: rootState.index.userInfo.id,
            patientid: getters.activeClient.id,
            sent_by: 'Doctor',
            has_read: false,
            sent_byid: rootState.index.userInfo.id,
            message: JSON.stringify(msg),
            sending: true
        }
        let updateRes = await API.setInquiryFormIsUnsigned(payload.inquiryid)
        if (updateRes.code === 1) {
            let res = await API.createMessage(message)
            if (res.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (res.code === 1) {
                message = res.result[0]
                message.sending = false
                message.recipient = getters.activeClient.id
                message.amount = payload.amount
                message.status = 'Pending'
                message.inquiryid = payload.inquiryid
                dispatch('socket/sendMessage', message, {
                    root: true
                })
                commit('pushMessageToClient', {
                    clientid: getters.activeClient.id,
                    message: message,
                    isActive: true,
                    isDoctor: true
                })
                Message({
                    message: '问卷发送成功, 等待用户确认',
                    type: 'success'
                })
            } else {
                console.log(res.err)
            }
        } else {
            console.log(updateRes.err)
        }
    },

    async handleInquiryformConfirmed ({ commit, dispatch }, payload) {
        // Mark all Inquiry type messages as Confirmed
        commit('markAllInquiryMessagesAsConfirmed', payload)
        // Reload the target patient's inquiry form and health record
        dispatch('inquiry/getHealthRecord', {
            clientid: payload.patientid,
            health_recordid: payload.health_recordid,
            isUpdate: true
        }, { root: true })
    },

    async sendInquiryFormItem ({ commit, rootState, getters, dispatch }, payload) {
        let msg = {
            inquiryid: payload.inquiryid,
            itemid: payload.item_id,
            questionid: payload.id,
            question: payload.question,
            options: payload.options,
            data_type: payload.data_type,
            status: 'Pending'
        }
        let message = {
            type: 'Question',
            health_recordid: getters.activeClient.health_recordid,
            doctor_userid: rootState.index.userInfo.id,
            patientid: getters.activeClient.id,
            sent_by: 'Doctor',
            has_read: false,
            sent_byid: rootState.index.userInfo.id,
            message: JSON.stringify(msg),
            sending: true
        }
        let res = await API.createMessage(message)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            message = res.result[0]
            message.sending = false
            message.recipient = getters.activeClient.id
            message.question = payload.question
            message.answer = ''
            message.status = 'Pending'
            dispatch('socket/sendMessage', message, {
                root: true
            })
            commit('pushMessageToClient', {
                clientid: getters.activeClient.id,
                message: message,
                isActive: true,
                isDoctor: true
            })
            Message({
                message: '问题发送成功, 等待用户回答',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    },

    async handleInquiryformItemConfirmed ({ commit, dispatch }, payload) {
        // Mark all Inquiry type messages as Confirmed
        commit('markInquiryItemAsConfirmed', payload)
        // Reload the target patient's inquiry form and health record
        dispatch('inquiry/getInquiryForm', {
            clientid: payload.patientid,
            inquiryid: payload.inquiryid,
            isUpdate: true
        }, { root: true })
    },

    async getHealthRecordByPatientIdAndStatus ({ commit, state, rootState, getters }) {
        if (getters.hasActiveClient) {
            let res = await API.getHealthRecordByPatientIdAndStatus(getters.activeClient.id, rootState.index.userInfo.doctorid, state.selectedKey, rootState.index.userInfo.hospitalid)
            if (res.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (res.code === 1) {
                commit('setActiveClientHealthRecords', res.result)
            } else {
                console.log(res.err)
            }
        }
    },

    async switchActiveUserHr ({ commit, getters, dispatch, rootState }, payload) {
        if (getters.hasActiveClient) {
            let user = getters.activeClient
            if (payload.secondType !== 'sub') {
                commit('setActiveClientHr', payload)
            } else {
                user = {
                    clientid: payload.patientid,
                    health_recordid: payload.id,
                }
            }
            await dispatch('inquiry/getHealthRecord', {
                health_recordid: user.health_recordid,
                clientid: user.id,
                isUpdate: true,
                secondType: payload.secondType
            }, { root: true })
            await dispatch('inquiry/getCareRecords', {
                health_recordid: user.health_recordid,
                patientid: user.id,
                doctorid: rootState.index.userInfo.doctorid,
                isUpdate: true,
                secondType: payload.secondType
            }, { root: true })
            if (user.openid) {
                await dispatch('inquiry/getInquiryForm', {
                    inquiryid: user.inquiryid,
                    clientid: user.id,
                    isUpdate: true,
                    secondType: payload.secondType
                }, { root: true })
            }
        }
    },
    async uploadAudio ({ commit, rootState, getters, dispatch }, payload) {
        if (payload.file) {
            let prefix = `chat/${getters.activeClient.id}/audio`
            let uploadRes = await API.uploadFile(prefix, payload.file, 'mp3')
            console.log(uploadRes)
            let content = {
                url: uploadRes.path.replace(prefix + '/', ''),
                duration: payload.duration
            }
            let message = {
                type: 'Audio',
                health_recordid: getters.activeClient.health_recordid,
                doctor_userid: rootState.index.userInfo.id,
                patientid: getters.activeClient.id,
                sent_by: 'Doctor',
                has_read: false,
                sent_byid: rootState.index.userInfo.id,
                message: JSON.stringify(content),
                sending: true
            }
            let res = await API.createMessage(message)
            if (res.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (res.code === 1) {
                message = res.result[0]
                message.sending = false
                message.recipient = getters.activeClient.id
                message.url = uploadRes.result
                message.duration = content.duration
                dispatch('socket/sendMessage', message, {
                    root: true
                })
                commit('pushMessageToClient', {
                    clientid: getters.activeClient.id,
                    message: message,
                    isActive: true,
                    isDoctor: true
                })
            } else {
                console.log(res.err)
            }
        }
    },

    async isUserExists ({ commit, rootState }, phone) {
        let res = await API.isUserExists({ phone: phone, hospitalid: rootState.index.userInfo.hospitalid })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('setSystemUsers', res.result)
        } else {
            console.log(res.err)
        }
    },

    async createOfflinePatient ({ commit, rootState }, payload) {
        let department = rootState.index.userInfo.department
        let doctorid = rootState.index.userInfo.doctorid
        Object.assign(payload, { department: department, doctorid: doctorid, hospitalid: rootState.index.userInfo.hospitalid })
        let res = await API.createOfflinePatient(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            let user = {}
            if (res.result.length) {
                user = Object.assign(res.result[0], { userid: res.result[0].patientid })
            }
            commit('setHrWithUserInfo', user)
            Message({
                message: '创建成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    },

    async createHealthRecord ({ commit, rootState }, payload) {
        let additionalInfo = {}, sections = {}, commonInfo = {}
        let userInfo = rootState.index.userInfo, targetObj = {}
        sections = JSON.parse(payload.hrTemplate.visible_sections)
        for (let item in sections) {
            additionalInfo[sections[item]] = payload.hrTemplate[sections[item]]
        }
        commonInfo = {
            department: userInfo.department,
            doctorid: userInfo.doctorid,
            hr_templateid: payload.hrTemplate.id,
            hospitalid: userInfo.hospitalid,
            first_subsequent_visit: payload.hrTemplate.first_subsequent_visit,
            patientid: payload.userid,
            name: payload.name,
            age: payload.age,
            gender: payload.gender,
            weight: payload.weight,
            patient_address: payload.address,
            patient_birthday: payload.birthday,
            patient_phone: payload.phone,
            family_memberid: payload.id,
            status: 'Inquiring',
            inquiry_date: new Date(),
            created_date: new Date()
        }
        Object.assign(targetObj, additionalInfo, commonInfo)
        delete targetObj.id
        let res = await API.createHealthRecord(targetObj)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            let user = {}
            if (res.result.length) {
                user = Object.assign(res.result[0], { userid: payload.userid, openid: payload.openid })
            }
            commit('inquiry/addPrescriptions', { health_recordid: res.result[0].id, result: [] }, { root: true })
            commit('setHrWithUserInfo', user)
            Message({
                message: '创建病历成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    },

    async getFamilyMembers ({ commit, rootState }, payload) {
        let res = await API.getFamilyMembers(Object.assign(payload, { hospitalid: rootState.index.userInfo.hospitalid }))
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setFamilyMembers', res.result)
        } else {
            console.log(res.err)
        }
    },

    async createFamilyMember ({ commit }, payload) {
        let res = await API.createFamilyMember(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setNewFamilyMember', res.result)
        } else {
            console.log(res.err)
        }
    },

    async updateFamilyMember ({ commit }, payload) {
        let res = await API.updateFamilyInfo(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setNewFamilyMember', res.result)
        } else {
            console.log(res.err)
        }
    },

    async getMergeUsers ({ rootGetters, commit, rootState }, family_memberid) {
        let res = await API.getMergeUsers({ family_memberid: family_memberid, hospitalid: rootState.index.userInfo.hospitalid })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            res.result.sort((a, b) => {
                return (b.userid === rootGetters['inquiry/activeHealthRecord'].patientid) - (a.userid === rootGetters['inquiry/activeHealthRecord'].patientid)
            })
            commit('setMergeUsers', res.result)
        } else {
            console.log(res.err)
        }
    },

    async confirmMergeUser ({ commit, rootState }, payload) {
        let duplicateUserIds = [], deleteUserIds = [], mergeFamilyMemberIds = []
        if (!payload.openid) {
            payload.checkedFamilyMembers.some(fm => {
                if (fm.openid) {
                    payload.id = fm.userid
                    return true
                }
            })
        }
        payload.checkedFamilyMembers.forEach(async fm => {
            if (fm.userid !== payload.id) {
                duplicateUserIds.push(fm.userid)
                if (!fm.openid) {
                    deleteUserIds.push(fm.userid)
                }
            }
            if (fm.id !== payload.family_memberid) {
                mergeFamilyMemberIds.push(fm.id)
            }
        })
        duplicateUserIds = Array.from(new Set(duplicateUserIds))
        deleteUserIds = Array.from(new Set(deleteUserIds))
        let res = await API.confirmMergeUser(duplicateUserIds, payload.id, deleteUserIds, payload.family_memberid, mergeFamilyMemberIds, rootState.index.userInfo.hospitalid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('removeMergeUsers', payload.id)
            Message({
                message: '操作成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    },

    async deleteHealthRecord ({ commit }, id) {
        let res = await API.deleteHealthRecord({ id: id })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('removeActiveClientHealthRecords', id)
            Message({
                message: '删除成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
