<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import '@/assets/css/bootstrap.min.css';
import '@/assets/css/icons.css';
import '@/assets/css/style.css';
export default {
  name: 'App',

  mounted () {
    window.addEventListener('offline', () => {
      this.$message('网络连接异常')
    })
  }
}
</script>
<style lang="less">
.el-scrollbar {
  .el-scrollbar__bar {
    opacity: 1;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
