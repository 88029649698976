import API from '@/utils/api'
import {
    Message
} from 'element-ui'

const state = {
    pageSize: 15, // 每页显示的个数
    pageNumber: null, // 有多少页
    pageIndex: 0, // 当前页码
    totalNumber: 0, // 有多少条记录
    loading: false,
    posts: [],
    postTypes: [],
    showList: true,
    post: {},
    postid: '',
    changed: false,
    uploadRes: {},
    keyWordCheckList: [],
    // keyWords: ['发热', '咳嗽', '腹泻', '感冒', '喘息']
    doctors: [],
    freshPage: false
}

// getters
const getters = {
    formatPosts (state) {
        return state.posts
    },
    formatPostDetail (state) {
        state.post['keyWordCheckList'] = state.post.tag ? state.post.tag.split(',') : []
        return state.post
    },
}

//mutations
const mutations = {
    setPosts (state, result) {
        state.posts = [...state.posts, ...result.list]
        state.pageNumber = result.pageNumber
        state.totalNumber = result.totalNumber
    },
    emptyPostList (state) {
        state.posts = []
    },
    emptyPostPageNumber (state) {
        state.pageNumber = null
    },
    switchPostLoading (state) {
        state.loading = !state.loading
    },
    setPostTypes (state, result) {
        state.postTypes = []
        state.postTypes = [{ type: '显示全部' }, ...result]
    },
    updatePostDetail (state, post) {
        if (post.length) {
            let index = state.posts.findIndex(item => post[0].id === item.id)
            if (index !== -1) {
                state.posts[index] = post[0]
                state.posts = JSON.parse(JSON.stringify(state.posts)) // getter监听不到posts的变化
            }
        }
        state.post = post[0]
    },
    switchShowList (state) {
        state.showList = !state.showList
    },
    setPostDetailId (state, id) {
        state.postid = id
    },
    setPostDetail (state, post) {
        if (post.length) {
            if (post.type === 'image') {
                state.post.cover_image = post[0].cover_image
            } else {
                state.post = {
                    title: post[0].title || '',
                    content: post[0].content || '',
                    cover_image: post[0].cover_image || '',
                    tag: post[0].tag || '',
                    views: post[0].views || 0,
                    counts: post[0].counts || 0,
                    state: post[0].state || '',
                    type: post[0].type || '',
                    keyWordCheckList: post[0].tag ? post[0].tag.split(',') : [],
                    userid: post[0].userid || '',
                    hospitalid: post[0].hospitalid || ''
                }
                state.keyWordCheckList = post[0].tag ? post[0].tag.split(',') : []
            }
        } else {
            state.post = {
                title: '',
                cover_image: ''
            }
        }
    },
    setUploadRes (state, res) {
        state.uploadRes = res
    },
    reducePosts (state, id) {
        state.posts.splice(state.posts.findIndex(post => post.id === id), 1)
    },
    addPostIntoList (state, res) {
        res.counts = 0
        state.posts = [res, ...state.posts]
    },
    setDoctors (state, res) {
        let doctors = []
        res.forEach(doctor => {
            if (!doctor.is_test) {
                doctors.push({
                    value: doctor.userid,
                    label: doctor.name
                })
            }
        })
        state.doctors = doctors
    },
    switchFreshPage (state, value) {
        state.freshPage = value
    },
    updatePostState (state, payload) {
        state.post[payload.field] = payload.value
    }
}

//actions
const actions = {
    async getPostList ({ state, rootState, commit }, payload) {
        Object.assign(payload, { pageSize: state.pageSize, hospitalid: rootState.index.userInfo.hospitalid })
        let res = await API.getPostList(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setPosts', res.result)
            commit('switchPostLoading')
        } else {
            console.log(res.err)
        }
    },
    async getPostTypes ({ rootState, commit }) {
        let res = await API.getPostTypes({ hospitalid: rootState.index.userInfo.hospitalidƒ })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setPostTypes', res.result)
        } else {
            console.log(res.err)
        }
    },
    async updatePost ({ commit }, payload) {
        let res = await API.updatePost(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('updatePostDetail', res.result)
            Message({
                message: '修改成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    },
    async getPostDetail ({ state, commit }) {
        let res = await API.getPostDetail(state.postid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setPostDetail', res.result)
        } else {
            console.log(res.err)
        }
    },
    async uploadPostImage ({ commit }, payload) {
        if (payload.file) {
            let uploadRes = await API.uploadPostImage(payload.file, payload.fileType, payload.postid, payload.type)
            if (uploadRes.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (uploadRes.code === 1) {
                if (payload.type === 'cover') {
                    let res = await API.updatePost({ id: payload.postid, cover_image: uploadRes.path })
                    if (res.status === 200) {
                        Message({ showClose: true, message: '封面上传成功', type: 'success' });
                        commit('setPostDetail', Object.assign(res.result, { type: 'image' }))
                    }
                } else if (payload.type === 'content') {
                    commit('setUploadRes', uploadRes)
                }
            } else {
                console.log(uploadRes.err)
            }
        }
    },
    async createPost ({ rootState, commit }) {
        let res = await API.createPost(rootState.index.userInfo.hospitalid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setPostDetailId', res.result[0].id)
            commit('setPostDetail', res.result)
            commit('addPostIntoList', res.result[0])
            console.log('create')
        } else {
            console.log(res.err)
        }
    },
    async deletePost ({ commit }, id) {
        let res = await API.deletePost(id)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('switchShowList')
            commit('reducePosts', id)
        } else {
            console.log(res.err)
        }
    },
    async getDoctors ({ rootState, commit }) {
        let res = await API.getDoctors({ hospitalid: rootState.index.userInfo.hospitalid })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setDoctors', res.result)
        } else {
            console.log(res.err)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}