let routes = [{
    path: '/',
    redirect: '/index'
}, {
    name: 'index',
    path: '/index',
    component: () => import('@/views/index/index'),
    meta: {
        title: '首页',
        path: 'index',
        isLogin: true,
        role: ['Doctor']
    }
}, {
    path: '/login',
    component: () => import('@/views/login/login'),
    meta: {
        title: '登录',
        isLogin: false,
        path: 'login'
    }
}, {
    path: '/register',
    component: () => import('@/views/register/register'),
    meta: {
        title: '注册',
        isLogin: false,
        path: 'register'
    }
}, {
    path: '/error',
    component: () => import('@/views/error/404'),
    meta: {
        title: 'error',
        isLogin: false,
        path: 'error'
    }
}, {
    path: '/infectiousReportCard/:id',
    component: () => import('@/views/print/infectiousReportCard'),
    meta: {
        title: '传染病报告卡',
        isLogin: true,
        path: 'error'
    }
}, {
    path: '/healthRecordPrint/:id',
    component: () => import('@/views/print/healthRecordPrint'),
    meta: {
        title: '打印病历',
        isLogin: true,
        path: 'error'
    }
}, {
    path: '/prescriptionPrint/:id/:hid',
    component: () => import('@/views/print/prescriptionPrint'),
    meta: {
        title: '打印处方',
        isLogin: true,
        path: 'error'
    }
}, {
    path: '*',
    redirect: '/error'
}]

export default routes;