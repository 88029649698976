// initial state
import API from '@/utils/api'
const state = {
    errMsg: ''
}

// getters
const getters = {}

//mutations
const mutations = {
    login (state, res) {
        localStorage.setItem('Koa:token', res.token)
    },
    logout () {
        localStorage.removeItem('Koa:token')
        window.location.href = '/login'
    },
    setErrMsg (state, msg) {
        state.errMsg = msg
    }
}

//actions
const actions = {
    async login ({
        commit
    }, payload) {
        let res = await API.login(payload.code, payload.password)
        if (res.code === 0) {
            if (res.errMsg) {
                commit('setErrMsg', res.errMsg)
            } else {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            }
        } else if (res.code === 1) {
            commit('login', res)
            payload.$router.push('/index')
        } else {
            console.log(res.err)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
