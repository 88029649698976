// initial state
import API from '@/utils/api'
import { Message } from 'element-ui'
const state = {
    medicineList: [],
    medicineTypes: [],
    pageSize: 15, // 每页显示的个数
    pageNumber: null, // 有多少页
    pageIndex: 0, // 当前页码
    totalNumber: 0, // 有多少条记录
    loading: false,
    medicineTypeMapping: { 'Patch': '贴剂', 'Oral': '口服', 'Spray': '喷剂', 'Atomization': '雾化' }
}

// getters
const getters = {

}

//mutations
const mutations = {
    setMedicineList (state, result) {
        state.medicineList = [...state.medicineList, ...result.list]
        state.pageNumber = result.pageNumber
        state.totalNumber = result.totalNumber
    },
    setMedicineTypes (state, result) {
        state.medicineTypes = result.map(item => {
            return {
                label: item.category,
                value: item.category
            }
        })
    },
    emptyMedicineList (state) {
        state.medicineList = []
    },
    emptyMedicinePageNumber (state) {
        state.pageNumber = null
    },
    switchMedicineLoading (state) {
        state.loading = !state.loading
    },
    updateMedicine (state, result) {
        state.medicineList.splice(state.medicineList.findIndex(item => item.id === result.id), 1, result)
    }
}

//actions
const actions = {
    async getMedicineList ({ state, rootState, commit }, payload) {
        Object.assign(payload, { pageSize: state.pageSize, hospitalid: rootState.index.userInfo.hospitalid })
        let res = await API.getMedicineList(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setMedicineList', res.result)
            commit('switchMedicineLoading')
        } else {
            console.log(res.err)
        }
    },

    async getMedicineTypes ({ commit }) {
        let res = await API.getMedicineTypes()
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setMedicineTypes', res.result)
        } else {
            console.log(res.err)
        }
    },

    async upsertMedicine ({ rootState, commit }, payload) {
        let res = {}, medicinePrice = { id: payload.priceid, medicineid: payload.id, hospitalid: rootState.index.userInfo.hospitalid, price: payload.price }
        if (!payload.id) {
            res = await API.upsertMedicine(Object.assign(payload, { hospitalid: rootState.index.userInfo.hospitalid }))
            if (res.status === 200 && res.result.length) {
                res = await API.upsertMedicinePrice(Object.assign(medicinePrice, { medicineid: res.result[0].id }))
            }
        } else {
            await API.upsertMedicine(payload)
            res = await API.upsertMedicinePrice(medicinePrice)
        }
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200 && res.result.length) {
            Object.assign(payload, { priceid: res.result[0].id })
            commit('updateMedicine', payload)
            Message({
                message: '操作成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    },

    async deleteMedicine ({ commit }, payload) {
        let res = await API.deleteMedicine(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('emptyMedicineList')
        } else {
            console.log(res.err)
        }
    },

    async emptyMedicinePrice ({ commit }, payload) {
        let res = await API.emptyMedicinePrice({ id: payload.priceid, price: 0 })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            payload.medicine.price = ''
            commit('updateMedicine', payload.medicine)
            Message({
                message: '操作成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
