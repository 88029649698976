// initial state
import API from '@/utils/api'
import { Message } from 'element-ui'
const state = {
    hospital: {
        id: '',
        name: '',
        address: '',
        phone: '',
        department: '',
        doctor_name: '',
        credit_code: '',
        telephone: '',
        license: '',
        licenseFiles: [],
        licenseFullURLFiles: [],
        permitFiles: [],
        permitFullURLFiles: [],
    },
    currentStep: 0,
    basicInfo: [
        {
            field: 'name',
            label: '诊所名称'
        }, {
            field: 'address',
            label: '地址'
        }, {
            field: 'phone',
            label: '座机电话'
        }, {
            field: 'department',
            label: '诊疗科目'
        }, {
            field: 'doctor_name',
            label: '法定代表人'
        }, {
            field: 'telephone',
            label: '手机号码'
        }, {
            field: 'credit_code',
            label: '统一社会信用代码'
        }, {
            field: 'license',
            label: '医疗机构职业许可证登记号'
        }
    ]
}

// getters
const getters = {}

//mutations
const mutations = {
    register (state, res) {
        localStorage.setItem('Koa:token', res.token)
    },
    // logout () {
    // 	localStorage.removeItem('Koa:token')
    // 	window.location.href = '/login'
    // }
    updateHospital (state, payload) {
        state.hospital[payload.field] = payload.value
    },
    updateCurrentStep (state, payload) {
        state.currentStep = state.currentStep + payload
    }
}

//actions
const actions = {

    async registerHospital ({ state, commit }) {
        let res = await API.registerHospital(state.hospital)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200 && res.result instanceof Array) {
            commit('register', res)
            commit('updateHospital', { field: 'id', value: res.result[0].id })
            commit('updateCurrentStep', 1)
        } else if (res.status === 200) {
            Message({
                message: res.result,
                type: "info"
            })
        } else {
            console.log(res.err)
        }
    },

    async updateHospital ({ state, commit }) {
        let res = await API.updateHospital(Object.assign(state.hospital, { permitFiles: state.hospital.permitFiles.join(','), licenseFiles: state.hospital.licenseFiles.join(',') }))
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('updateCurrentStep', 2)
            localStorage.removeItem('Koa:token')
        } else {
            console.log(res.err)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
