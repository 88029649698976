import API from '@/utils/api'
import moment from 'moment'
const state = {
    hrAreaChart: {},
    hrStatusCount: {},
    caringHRs: [],
    reminderList: [],
    lastCaringStatusPieChart: {},
    subscribeAreaBarChart: [],
    subscribeStatus: ['预约中', '已完成', '已过期', '已取消'],
    subscribers: {},
    originSubscribers: []
}

// getters
const getters = {
    formatLineChart (state) {
        // state.hrAreaChart 
        let series = [], xDate = []
        for (let i = 13; i > -1; i--) {
            xDate.push(moment().subtract(i, "days").format("YYYY-MM-DD"))
        }
        let xAxis = {
            type: 'category',
            boundaryGap: false,
            data: xDate.map(date => {
                return moment(date).format('MM-DD')
            })
        }
        let yAxis = {
            type: 'value',
            rotate: 50
        }
        let userCount = []
        xDate.forEach(date => {
            let value = state.hrAreaChart[date] ? state.hrAreaChart[date] : 0
            userCount.push(value)
        })
        series.push({
            type: 'line',
            data: userCount,
            connectNulls: true,
            itemStyle: {
                normal: {
                    lineStyle: {
                        color: '#5ab1ef'
                    }
                }
            }
        })
        return { xAxis: xAxis, yAxis: yAxis, series: series }
    },
    formatAreaChart (state) {
        let titles = [], series = [], xDate = []

        let xAxis = {
            type: 'category',
            boundaryGap: false,
            data: xDate.map(date => {
                return moment(date).format('MM-DD')
            })
        }
        for (let item in state.hrAreaChart) {
            let statusCount = []
            xDate.forEach(date => {
                let value = state.hrAreaChart[item][date] ? state.hrAreaChart[item][date] : 0
                statusCount.push(value)
            })
            titles.push(item)
            series.push({
                name: item,
                type: 'line',
                stack: '总量',
                areaStyle: {},
                data: statusCount,
                connectNulls: true
            })
        }
        return { xAxis: xAxis, legend: { data: titles }, series: series }
    },
    formatBarChart (state) {
        let title = {}, xDate = [], xAxis = {}, yAxis = {}, tooltip = {}, legend = {}
        for (let i = -3; i <= 3; i++) {
            xDate.push(moment().add(i, "days").format("MM-DD"))
        }
        title = {
            text: '预约统计',
            left: '20px',
            textStyle: {
                color: "#436EEE",
                fontSize: 17,
            }
        }
        tooltip = {
            trigger: "axis",
            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            }
        }
        xAxis = {
            data: xDate,
            splitLine: {
                show: false,
            },
        }
        yAxis = {
            splitLine: {
                show: false,
            },
        }
        let statusItems = []
        if (state.subscribeAreaBarChart.length) {
            statusItems = state.subscribeAreaBarChart
        }
        legend = {
            itemWidth: 15,
            itemHeight: 15,
            data: state.subscribeStatus,
        }
        return { xAxis: xAxis, yAxis: yAxis, series: statusItems, title: title, tooltip: tooltip, legend: legend }
    },
    formatPieChart (state) {
        let legend = {}, series = {}, title = {}, legendData = [], seriesData = [], tooltip = {}
        for (let key in state.lastCaringStatusPieChart) {
            legendData.push(key)
            seriesData.push({
                value: state.lastCaringStatusPieChart[key],
                name: key,
            })
        }
        legend = {
            left: 'center',
            top: 'bottom',
            data: legendData,
        },
            tooltip = {
                trigger: 'item',
                formatter: '{b} : {c}'
            },
            series = {
                type: 'pie',
                radius: '55%',
                center: ['50%', '50%'],
                data: seriesData,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
            }
        title = {
            text: `${Object.keys(state.lastCaringStatusPieChart).length ? '治疗结果' : '暂无数据'}`,
            left: 'left'
        }
        return { title: title, legend: legend, series: series, tooltip: tooltip }
    }
}

//mutations
const mutations = {
    setHRAreaChart (state, result) {
        state.hrAreaChart = result
    },
    setHrStatusCount (state, result) {
        state.hrStatusCount = result
    },
    setSubscribers (state, result) {
        let dayList = [], index = 0, currentSubscribers = [], hourList = [], inWorkHour = false
        for (let day in result) {
            dayList.push({
                label: (index === 0 ? '今天' : index === 1 ? '明天' : '后天') + ' ' + day,
                value: day
            })
            if (index === 0) {
                state.subscribers.activeDay = day
                let currentHour = `${new Date().getHours() + 1}:00`
                for (let hour in result[day]) {
                    let endTime = hour.split('-').length ? hour.split('-')[1] : ''
                    if (endTime === currentHour) {
                        state.subscribers.activeHour = hour
                        inWorkHour = true
                        currentSubscribers = result[day][hour]
                    }
                    hourList.push(hour)
                }
                if (!inWorkHour) {
                    state.subscribers.activeHour = '全天'
                    currentSubscribers = result[day]['全天']
                }
            }
            index++;
        }
        state.subscribers.dayList = dayList
        state.subscribers.hourList = hourList
        state.subscribers.currentSubscribers = currentSubscribers
        state.originSubscribers = result
    },
    setReminderList (state, result) {
        result.forEach(item => {
            item.gender = item.gender == 0 ? '女' : '男'
            if (item.age) {
                let ages = item.age.toFixed(4).split('.') // e.g., 3.1207 表示 3岁12月07天
                let ageYear = parseInt(ages[0]),
                    ageMonths = parseInt(ages[1].substring(0, 2)),
                    ageDays = parseInt(ages[1].substring(2, 4))
                item['ageText'] = (ageYear > 0 ? ageYear + '岁' : (ageMonths > 0 ? ageMonths + '个月' : (ageDays > 0 ? ageDays + '天' : '')))
            }
        })
        state.reminderList = result
    },
    setLastCaringStatus (state, result) {
        state.lastCaringStatusPieChart = result
    },
    setSubscribeAreaBarChart (state, result) {
        state.subscribeAreaBarChart = result
    },
    updateCurrentSubscribers (state, payload) {
        if (payload.type === 'day') {
            state.subscribers.activeDay = payload.value
            let currentDayList = state.originSubscribers[payload.value]
            state.subscribers.hourList = Object.keys(currentDayList)
            state.subscribers.activeHour = state.subscribers.hourList[0]
            state.subscribers.currentSubscribers = currentDayList[state.subscribers.activeHour]
        } else {
            state.subscribers.activeHour = payload.value
            state.subscribers.currentSubscribers = state.originSubscribers[state.subscribers.activeDay][state.subscribers.activeHour]
        }
    }
}

//actions
const actions = {
    async getHealthRecordAreaChart ({ commit, rootState }) {
        let res = await API.getHealthRecordAreaChart(rootState.index.userInfo.hospitalid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('setHRAreaChart', res.result)
        } else {
            console.log(res.err)
        }
    },
    async getHealthRecordStatusCount ({ commit, rootState }) {
        let res = await API.getHealthRecordStatusCount(rootState.index.userInfo.hospitalid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('setHrStatusCount', res.result)
        } else {
            console.log(res.err)
        }
    },
    async getSubscribeAreaBarChart ({ commit, rootState }) {
        let res = await API.getSubscribeAreaBarChart(rootState.index.userInfo.hospitalid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('setSubscribeAreaBarChart', res.result)
        } else {
            console.log(res.err)
        }
    },
    async getSubscriberList ({ commit, rootState }) {
        let res = await API.getSubscriberList(rootState.index.userInfo.hospitalid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('setSubscribers', res.result)
        } else {
            console.log(res.err)
        }
    },
    async getReminderList ({ commit, rootState }) {
        let res = await API.getReminderList(rootState.index.userInfo.hospitalid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('setReminderList', res.result)
        } else {
            console.log(res.err)
        }
    },
    async getLastCaringStatusPieChart ({ commit, rootState }) {
        let res = await API.getLastCaringStatus(rootState.index.userInfo.hospitalid)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('setLastCaringStatus', res.result)
        } else {
            console.log(res.err)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
