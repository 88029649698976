// initial state
import API from '@/utils/api'
import {
    Message
} from 'element-ui'
import {
    isBlank
} from '@/utils/util'

const state = {
    userInfo: {},
    status: { text: '离线', class: 'offline' },
    activeTab: 'profile',
    showRightPanel: false,
    showDropdownMenu: false,
    showRightBar: false,
    showChatPanel: false,
    showChatHistoryPanel: false,
    showProfilePanel: true,
    showConfigurationPanel: false,
    showApproval: false,
    showTreatmentLog: false,
    showPostList: false,
    showDashboard: true,
    showBotConfig: false,
    showMedicineList: false,
    showDoctorList: false,
    showSubscribe: false,
    medicines: [],
    roomVisible: false,
    rtcSig: {
        sdkAppId: '',
        userSig: ''
    },
    isDeclined: false,
    localStream: '',
    roomId: '',
    sideBarOperationList: ['showApproval', 'showTreatmentLog', 'showPostList', 'showBotConfig', 'showDashboard', 'showMedicineList', 'showDoctorList', 'showSubscribe'],
    hrTemplates: [],
    diagnosisTemplates: [],
    allSections: { "主诉": "summary", "现病史": "symptom", "个人史": "personal_history", "既往史": "sick_history", "体格检查": "body_inspection", "辅助检查": "auxiliary_inspection", "临床诊断": "clinical_diagnose", "专科检查": "special_inspection", "处理": "dispose", "治疗史": "treatment_history", "过敏史": "allergic_history", "临床处置": "clinical_treatment" }
}

// getters
const getters = {
    doctor_signature_url (state) {
        return state.userInfo.doctor_signature_url
    },
    doctor_signature_path (state) {
        return state.userInfo.doctor_signature
    }
}

//mutations
const mutations = {
    setUserInfo (state, userInfo) {
        let roles = userInfo.doctor_role ? userInfo.doctor_role.split(',') : []
        userInfo.roles = []
        userInfo.doctorName = userInfo.doctor_name
        userInfo.doctorDescription = userInfo.doctor_description
        userInfo.doctorDepartment = userInfo.department
        roles.forEach(role => {
            if (role === 'Doctor') {
                userInfo.roles.push('医生')
                userInfo.isDoctor = true
            } else if (role === 'Pharmacist') {
                userInfo.roles.push('药剂师')
                userInfo.isPharamacist = true
            } else if (role === 'Editor') {
                userInfo.roles.push('内容编辑')
                userInfo.isEditor = true
            } else if (role === 'Manager') {
                userInfo.roles.push('助手管理')
                userInfo.isManager = true
            } else if (role === 'Administrator') {
                userInfo.roles.push('管理员')
                userInfo.isAdministrator = true
            }
        })
        if (userInfo.isPharamacist && !userInfo.isDoctor) {
            state.showApproval = true
        }
        state.userInfo = userInfo
        // state.status = {
        //     text: '离线',
        //     class: 'offline'
        // }
    },
    updateUserName (state, name) {
        state.userInfo.doctor_name = name
    },
    updateUserDes (state, des) {
        state.userInfo.doctor_description = des
    },
    updateUserDep (state, dep) {
        state.userInfo.department = dep
    },
    setMedicines (state, medicines) {
        medicines.forEach(medicine => {
            medicine.unit = null
            medicine.frequency = null
            medicine.dosage_type = null
            medicine.per_quantity = null
            medicine.per_unit = null
            medicine.new_per_unit = null
        })
        state.medicines = medicines
    },
    setHrTemplates (state, result) {
        state.hrTemplates = result
    },
    updateMedicine (state, res) {
        if (res.length) {
            let index = state.medicines.findIndex(item => res[0].id === item.id)
            if (index !== -1) {
                state.medicines[index] = res[0]
                state.medicines[index].dosage_type = res[0].prefer_dosage ? res[0].prefer_dosage : null
            }
        }
    },
    addMedicine (state, { medicine }) {
        medicine.checked = false
        medicine.dosage = null
        medicine.unit = null
        medicine.frequency = null
        medicine.dosage_type = null
        medicine.per_quantity = null
        medicine.per_unit = null
        state.medicines = [...state.medicines, medicine]
    },
    login (state) {
        state.status = {
            text: '登陆中...',
            class: 'connect'
        }
    },
    online (state) {
        state.status = {
            text: '在线',
            class: 'online'
        }
    },
    offline (state) {
        state.status = {
            text: '离线',
            class: 'offline'
        }
    },
    reconnect (state) {
        state.status = {
            text: '重新连接...',
            class: 'connect'
        }
    },
    openRightPanel (state) {
        state.showRightPanel = true
        state.showDropdownMenu = false
    },
    hideRightPanel (state) {
        state.showRightPanel = false
    },
    openDropdownMenu (state) {
        state.showDropdownMenu = true
    },
    hideDropdownMenu (state) {
        state.showDropdownMenu = false
    },
    openRightBar (state) {
        state.showRightBar = true
    },
    hideRightBar (state) {
        state.showRightBar = false
    },
    openChatContent (state) {
        state.sideBarOperationList.forEach(item => {
            state[item] = false
        })
    },
    switchOperation (state, value) {
        state.sideBarOperationList.forEach(item => {
            if (value === item) {
                state[item] = true
            } else {
                state[item] = false
            }
        })
    },
    openChatPanel (state) {
        state.showChatPanel = true
        state.showChatHistoryPanel = false
        state.showProfilePanel = false
        state.showConfigurationPanel = false
        state.activeTab = 'chat'
        state.showApproval = false
        state.showPostList = false
        state.showTreatmentLog = false
        state.showDashboard = false
        state.showMedicineList = false
        state.showBotConfig = false
        state.showDoctorList = false
        state.showSubscribe = false
    },
    openChatHistoryPanel (state) {
        state.showChatHistoryPanel = true
        state.showChatPanel = false
        state.showProfilePanel = false
        state.showConfigurationPanel = false
        state.activeTab = 'history'
    },
    openProfilePanel (state) {
        state.showProfilePanel = true
        state.showChatHistoryPanel = false
        state.showChatPanel = false
        state.showConfigurationPanel = false
        state.activeTab = 'profile',
        state.showRightPanel = false,
        state.showDashboard = true
    },
    openConfigurationPanel (state) {
        state.showConfigurationPanel = true
        state.showProfilePanel = false
        state.showChatHistoryPanel = false
        state.showChatPanel = false
        state.activeTab = 'configuration'
    },
    setDoctorName (state) {
        state.userInfo.doctorName = state.userInfo.doctor_name
    },
    resetDoctorName (state) {
        state.userInfo.doctor_name = state.userInfo.doctorName
    },
    setDoctorDescription (state) {
        state.userInfo.doctorDescription = state.userInfo.doctor_description
    },
    resetDoctorDescription (state) {
        state.userInfo.doctor_description = state.userInfo.doctorDescription
    },
    setDoctorDepartment (state) {
        state.userInfo.doctorDepartment = state.userInfo.department
    },
    resetDoctorDepartment (state) {
        state.userInfo.department = state.userInfo.doctorDepartment
    },
    setDoctorAvatar (state, url) {
        state.userInfo.doctor_avatar_url = url
    },
    setDoctorSignature (state, url) {
        state.userInfo.doctor_signature_url = url
    },
    openRoom (state) {
        state.roomVisible = true
    },
    shotdownRoom (state) {
        state.roomVisible = false
    },
    setRtcSig (state, rtcSig) {
        state.rtcSig = Object.assign({}, rtcSig)
    },
    setIsDeclined (state, status) {
        state.isDeclined = status
    },
    setLocalStream (state, stream) {
        state.localStream = stream
    },
    closeStream (state) {
        // 停止本地流，关闭本地流内部的音视频播放器
        state.localStream.stop()
        // 关闭本地流，释放摄像头和麦克风访问权限
        state.localStream.close()
    },
    setRoomId (state, roomId) {
        state.roomId = roomId
    },
    upsertHRTemplate (state, payload) {
        if (payload.isEdit) {
            if (payload.result.length) {
                state.hrTemplates.splice(state.hrTemplates.findIndex(item => item.id === payload.result[0].id), 1, payload.result[0])
            }
        } else {
            if (payload.result.length) state.hrTemplates.push(payload.result[0])
        }
    },
    updateHRTemplates (state, value) {
        state.hrTemplates = value
    },
    setDiagnosisTemplates (state, result) {
        state.diagnosisTemplates = result
    },
    upsertDiagnosisTemplate (state, result) {
        if (result.type === 'update')
            state.diagnosisTemplates.splice(state.diagnosisTemplates.findIndex(item => item.id === result.oldid), 1, result)
        else
            state.diagnosisTemplates.push(result)
    }
}

//actions
const actions = {
    async getUserInfo ({ commit, dispatch }) {
        let res = await API.getUserInfo()
        if (res.code === 0 || (res.code === 1 && !res.result.length)) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1 && res.result.length) {
            commit('setUserInfo', res.result[0])
            await dispatch('getHrTemplates')
            await dispatch('getDiagnosisTemplates')
        }
        else {
            console.log(res.err)
        }
    },

    async getMedicines ({ state, commit }, payload) {
        let res = await API.getAllMedicines(Object.assign(payload, { hospitalid: state.userInfo.hospitalid }))
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('setMedicines', res.result)
        } else {
            console.log(res.err)
        }
    },

    async getHrTemplates ({ state, commit }) {
        let res = await API.getHrTemplates({ hospitalid: state.userInfo.hospitalid })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('setHrTemplates', res.result)
        } else {
            console.log(res.err)
        }
    },

    async getDiagnosisTemplates ({ state, commit }) {
        let res = await API.getDiagnosisTemplates({ hospitalid: state.userInfo.hospitalid })
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setDiagnosisTemplates', res.result)
        } else {
            console.log(res.err)
        }
    },

    async updateAvatar ({ state, commit }, payload) {
        if (payload.file) {
            let uploadRes = await API.uploadAvatar(payload.file, payload.fileType, state.userInfo.doctorid)
            if (uploadRes.code === 0) {
                localStorage.removeItem('Koa:token')
                window.location.href = '/login'
            } else if (uploadRes.code === 1) {
                let doctor = {
                    id: state.userInfo.doctorid,
                    avatar_url: uploadRes.path
                }
                let res = await API.updateDoctorInfo(doctor)
                if (res.code === 1) {
                    Message({ showClose: true, message: '医生头像修改成功', type: 'success' });
                    commit('setDoctorAvatar', uploadRes.result)
                }
            } else {
                console.log(uploadRes.err)
            }
        }
    },

    async updateName ({ state, commit }) {
        let doctor = {
            id: state.userInfo.doctorid,
            name: state.userInfo.doctor_name
        }
        if (isBlank(doctor.name)) {
            Message({ showClose: true, message: '医生昵称不能为空', type: 'warning' });
            return
        }
        let res = await API.updateDoctorInfo(doctor)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            Message({ showClose: true, message: '医生昵称修改成功', type: 'success' });
            commit('setDoctorName')
        } else {
            commit('resetDoctorName')
        }
    },

    async updateDescription ({ state, commit }) {
        let doctor = {
            id: state.userInfo.doctorid,
            description: state.userInfo.doctor_description
        }
        if (isBlank(doctor.description)) {
            Message({ showClose: true, message: '医生描述不能为空', type: 'warning' });
            return
        }
        let res = await API.updateDoctorInfo(doctor)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            Message({ showClose: true, message: '医生描述修改成功', type: 'success' });
            commit('setDoctorDescription')
        } else {
            commit('resetDoctorDescription')
        }
    },

    async updateDepartment ({ state, commit }) {
        let doctor = {
            id: state.userInfo.doctorid,
            department: state.userInfo.department
        }
        if (isBlank(doctor.department)) {
            Message({ showClose: true, message: '科室不能为空', type: 'warning' });
            return
        }
        let res = await API.updateDoctorInfo(doctor)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            Message({ showClose: true, message: '科室修改成功', type: 'success' });
            commit('setDoctorDepartment')
        } else {
            commit('resetDoctorDepartment')
        }
    },

    async fetchUserSig ({ state, commit }) {
        let res = await API.getUserSig(state.userInfo.id)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('setRtcSig', res.result)
        }
    },
    async updateMedicine ({ commit }, payload) {
        let res = await API.updateMedicine(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('updateMedicine', res.result)
        } else {
            console.log(res.err)
        }
    },
    async updateHRTemplate ({ state, commit }, payload) {
        let res = await API.updateHRTemplate(Object.assign(payload, { hospitalid: state.userInfo.hospitalid }))
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            commit('upsertHRTemplate', { result: res.result, isEdit: payload.isEdit })
            Message({
                message: '操作成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    },
    async upsertDiagnosisTemplate ({ commit, state }, payload) {
        let res = {}, type = 'add'
        if (payload.id) {
            let template = Object.assign({}, payload)
            type = 'update'
            if (!payload.hospitalid) {
                delete template.id
            }
            Object.assign(template, { hospitalid: state.userInfo.hospitalid, description: payload.illnessItems.join(',') })
            res = await API.upsertDiagnosisTemplate(template)
        } else {
            Object.assign(payload, { hospitalid: state.userInfo.hospitalid, description: payload.illnessItems.join(',') })
            res = await API.upsertDiagnosisTemplate(payload)
        }
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200 && res.result.length) {
            commit('upsertDiagnosisTemplate', Object.assign(res.result[0], { oldid: payload.id, type: type }))
            Message({
                message: '操作成功',
                type: 'success'
            })
        } else {
            console.log(res.err)
        }
    },
    async changePassword ({ state }, payload) {
        let res = await API.changePassword(Object.assign(payload, { id: state.userInfo.id }))
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.code === 1) {
            if (res.result === '密码错误') {
                Message({
                    message: '密码错误',
                    type: 'info'
                })
            } else {
                Message({
                    message: '修改成功',
                    type: 'success'
                })
                setTimeout(() => {
                    localStorage.removeItem('Koa:token')
                    window.location.href = '/login'
                }, 1000);
            }
        } else {
            console.log(res.err)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}