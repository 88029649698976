import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import register from './modules/register'
import index from './modules/index'
import chat from './modules/chat'
import socket from './modules/socket'
import approval from './modules/approval'
import inquiry from './modules/inquiry'
import dashboard from './modules/dashboard'
import post from './modules/post'
import botQA from './modules/botQA'
import medicine from './modules/medicine'
import doctor from './modules/doctor'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        login,
        register,
        index,
        chat,
        socket,
        approval,
        inquiry,
        dashboard,
        post,
        botQA,
        medicine,
        doctor
    }
})
