import { Message } from 'element-ui'
import axios from 'axios'
import OSS from './oss'
import moment from "moment"
moment.locale("zh-cn")
// const baseURL = 'http://192.168.2.4:3000'
// const baseURL = 'http://172.20.10.7:3000'
const baseURL = 'https://doc.wotpatch.com'

let instance = axios.create({
    baseURL: baseURL,
    timeout: 30000,
    withCredentials: true
});
const networkError = 'Network Error'

instance.defaults.headers.common['Authorization'] = 'Beaer ' + localStorage.getItem('Koa:token')

class API {
    get baseURL () {
        return baseURL
    }

    updateToken (res) {
        if (res.data.token) {
            instance.defaults.headers.common.Authorization = 'Beaer ' + res.data.token
        }
    }

    request (url, method = 'GET', data = {}) {
        return new Promise((resolve, reject) => {
            instance.request({
                url: url,
                method: method,
                data: data,
            }).then(res => {
                this.updateToken(res)
                if (res.data.status === 500) {
                    this.showErrorTips()
                }
                resolve(res)
            }).catch(err => {
                this.showErrorTips(err.includes('Network Error'))
                reject(err)
            })
        })
    }

    login = (code, password) => {
        return this.request('/commons/webLogin', 'POST', {
            code: code,
            password: password
        }).then(res => { return res.data }).catch(err => { return err })
    }


    getUserInfo = () => {
        return this.request('/users/getUserInfo', 'GET').then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    updateDoctorInfo = (payload) => {
        return this.request('/doctors/update', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            console.log('err', err)
            return err
        })
    }

    updateUser = (payload) => {
        return this.request('/users/update', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    createUser = (payload) => {
        return this.request('/users/create', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    getUsersByHealthRecordStatus = (doctorid, patientids, status, searchKey) => {
        return instance.post('/users/getUsersByHealthRecordStatus', {
            doctor_userid: doctorid,
            patientids: patientids,
            status: status,
            searchKey: searchKey
        }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getMessages = (patientid, doctor_userid) => {
        return instance.post('/chatMessages/getMessages', {
            patientid: patientid,
            doctor_userid: doctor_userid,
            targetid: patientid
        }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    createMessage = meg => {
        return instance.post('/chatMessages/create', meg).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    updateMessageStatus = msg => {
        msg.has_read = true
        return instance.post('/chatMessages/updateById', msg).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getOssInfo = () => {
        return instance.post('/commons/getOssSignature').then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data.result
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    uploadFile = async (prefix, file, fileType) => {
        let ossInfo = await this.getOssInfo()
        let res = await OSS.uploadFileToOss(prefix, file, fileType, ossInfo)
        if (res.status === 200) {
            return this.getFileUrl(res.key)
        } else {
            this.showErrorTips()
            return res.error
        }
    }

    uploadHospitalFiles = async (payload) => {
        let ossInfo = await this.getOssInfo()
        let path = `hospital/${payload.id}`
        let res = await OSS.uploadFileToOss(path, payload.file, payload.fileType, ossInfo)
        if (res.status === 200) {
            return Object.assign(this.getFileUrl(res.key), { path: res.path })
        } else {
            this.showErrorTips()
            return res.error
        }
    }

    uploadAvatar = async (file, fileType, doctorid) => {
        let ossInfo = await this.getOssInfo()
        let path = `doctors/${doctorid}/avatar`
        let res = await OSS.uploadFileToOss(path, file, fileType, ossInfo)
        if (res.status === 200) {
            return this.getFileUrl(res.key)
        } else {
            this.showErrorTips()
            return res.error
        }
    }

    uploadSignature = async (file, fileType, doctorid) => {
        let ossInfo = await this.getOssInfo()
        let path = `doctors/${doctorid}/e_signature`
        let res = await OSS.uploadFileToOss(path, file, fileType, ossInfo, true)
        if (res.status === 200) {
            return this.getFileUrl(res.key)
        } else {
            this.showErrorTips()
            return res.error
        }
    }

    getFileUrl = path => {
        return instance.post('/commons/getOssFileUrl', { path: path }).then(res => {
            if (res.data.status === 200) {
                res.data.path = path
            }
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    upsertDoctorInfo = doctor => {
        return instance.post('/doctors/upsert', doctor).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(() => {
            this.showErrorTips()
        })
    }

    showErrorTips (isNetworkError) {
        Message({
            showClose: true,
            message: isNetworkError ? '网络不通，请检查你的网络' : '服务器错误，请稍后再试。',
            type: isNetworkError ? 'info' : 'error'
        });
    }

    createMessage = meg => {
        return instance.post('/chatMessages/create', {
            ...meg
        }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getPrescriptions = (hospitalid, searchInfo, pageSize) => {
        return instance.post('/prescriptions/list', {
            hospitalid: hospitalid,
            status: searchInfo.status,
            page: searchInfo.page,
            name: searchInfo.name,
            pageSize: pageSize
        }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getPrescriptionItems = id => {
        return instance.post('/prescriptions/items', {
            id: id
        }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    createPrescription = (prescription) => {
        return instance.post('/prescriptions/create', prescription).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    upsertPrescriptionItems = (items) => {
        return instance.post('prescriptionItems/massUpsert', { items: items }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }
    getPrescriptionStatusOptions = (hospitalid) => {
        return instance.post('/prescriptions/prescriptionStatusOptions', {
            hospitalid: hospitalid
        }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    updatePrescriptionStatus = (id, payload) => {
        let prescription = {
            id: id,
            status: payload.status,
            health_recordid: payload.health_recordid,
        }
        Object.assign(prescription, payload.tempPrescription)
        return instance.post('/prescriptions/upsert', prescription).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getInquiryDetail = (inquiryId) => {
        return instance.post(`/inquiry/query/${inquiryId}`).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getInquiryQuestion = (userid, inquiryId) => {
        return instance.post(`/inquiry/query/${inquiryId}`).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            let inquiryTemplateId = res.data.result[0].templateid
            return instance.post('/inquiryQuestions/all/inquiry', {
                id: inquiryId,
                userid: userid,
                templateid: inquiryTemplateId
            })
        }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getAllMedicines = (payload) => {
        return instance.post('/medicines/all', payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getHealthRecord = (id) => {
        return instance.post('/healthRecords/find', {
            id: id
        }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            console.log('err', err)
            this.showErrorTips()
            return err
        })
    }

    getSubscribes = (payload) => {
        return instance.post('/subscribes/findDetail', payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            console.log('err', err)
            this.showErrorTips()
            return err
        })
    }

    updateHealthRecord = (record) => {
        return instance.post('/healthRecords/update', record).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    upsertInquiryItem = (inquiryItems) => {
        return instance.post('/inquiryItems/upsert', inquiryItems).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    updatePrescription = (prescription) => {
        return instance.post('/prescriptions/upsert', prescription).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getPrescriptionsByHealthRecordId = (healthRecordid) => {
        return instance.post(`/prescriptions/healthrecord/${healthRecordid}`, {}).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getPrescription (id) {
        return this.request('/prescriptions/find', 'POST', { id: id }).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    updatePrescriptionItems = (prescriptionItems) => {
        return instance.post('/prescriptions/items/upsert', prescriptionItems).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    deletePrescriptionItems = (prescriptionId) => {
        return instance.delete(`/prescriptionItems/${prescriptionId}`).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    createMedicine = (medicine) => {
        return instance.post('/medicines/create', medicine).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    setInquiryFormIsUnsigned = (id) => {
        return instance.post('/inquiry/update/' + id, { is_signed: false }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getCareRecordsByHealthRecordId = (care) => {
        return instance.post(`/cares/all`, care).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    upsertCareRecord = (payload) => {
        return instance.post(`/cares/upsert`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getLogByHospitalId = (payload) => {
        return instance.post(`/treatmentLogs/getLogByHospitalId`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    exportData = (payload) => {
        return instance.post(`/healthRecords/exportData`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips()
            return err
        })
    }

    getHealthRecordByPatientIdAndStatus = (patientid, doctorid, status, hospitalid) => {
        return instance.post(`/healthRecords/findByPatientIdAndStatus`, { patientid: patientid, doctorid: doctorid, status: status, hospitalid: hospitalid }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getUserSig = (userid) => {
        return instance.post(`/commons/getUserSig`, { userid: userid }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getHealthRecordAreaChart = (hospitalid) => {
        return instance.post(`/healthRecords/getLast2WeekInquiryPatient`, { hospitalid: hospitalid }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getHealthRecordStatusCount = (hospitalid) => {
        return instance.post(`/healthRecords/statusCount`, { hospitalid: hospitalid }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getLastCaringHealthRecord = (hospitalid) => {
        return instance.post(`/healthRecords/find`, { hospitalid: hospitalid, status: 'Inquiring', type: 'lastInquiring' }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getReminderList = (hospitalid) => {
        return instance.post(`/cares/reminderList`, { hospitalid: hospitalid }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getLastCaringStatus = (hospitalid) => {
        return instance.post(`/healthRecords/lastCaringStatus`, { hospitalid: hospitalid }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    deleteInquiryItem = (ids) => {
        return instance.post(`/inquiryItems/delete`, { ids: ids }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    updateMedicine = (payload) => {
        return instance.post(`/medicines/update`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getPostList = (payload) => {
        return instance.post(`/posts/pagingList`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getPostTypes = () => {
        return instance.post(`/posts/types`).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    updatePost = (payload) => {
        return instance.post(`/posts/update`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getPostDetail = (postid) => {
        return instance.post(`/posts/${postid}`).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    uploadPostImage = async (file, fileType, postid, type) => {
        let ossInfo = await this.getOssInfo(), path
        if (type === 'content') path = `post/${postid}/content_image`
        else path = `post/${postid}/cover_image`
        let res = await OSS.uploadFileToOss(path, file, fileType, ossInfo)
        if (res.status === 200) {
            return this.getFileUrl(res.key)
        } else {
            this.showErrorTips()
            return res.error
        }
    }

    createPost = (hospitalid) => {
        return instance.post(`/posts/create`, { state: 'Draft', hospitalid: hospitalid }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    deletePost = (id) => {
        return instance.post(`/posts/delete`, { id: id }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getDoctors = (payload) => {
        return instance.post(`/doctors/findByHospital`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getBotQAList = (payload) => {
        return instance.post(`/bot_qa/all`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getBotPostList = (searchKey) => {
        return instance.post(`/posts/all`, { searchKey: searchKey }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getBotQuestion = (bot_qaid) => {
        return instance.post('/botQuestions/findObj', { bot_qaid: bot_qaid }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    deleteBotQuestion = (id) => {
        return instance.post(`/botQuestions/delete`, { id: id }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    batchDeleteAndInsertBotQuestion = (botQuestion) => {
        return instance.post(`/botQuestions/deleteAndInsert`, botQuestion).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    deleteBotQA = (id) => {
        return instance.post(`/bot_qa/delete`, { id: id }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    createBotQA = (botQA) => {
        return instance.post(`/bot_qa/create`, botQA).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    batchUpdateBotQA = (botQAList) => {
        return instance.post(`/bot_qa/massUpdate`, botQAList).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    sendChatMessage = (payload) => {
        return instance.post(`/commons/chatbot/message`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getBotQACategories = (hospitalid) => {
        return instance.post(`/bot_qa/categories`, { hospitalid: hospitalid }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    isUserExists = (payload) => {
        return instance.post(`/commons/isUserExists`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    createOfflinePatient = (payload) => {
        return instance.post(`/commons/createOfflinePatientInfo`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getHrTemplates = (payload) => {
        return instance.post(`/healthRecordTemplates/all`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getHrTemplate = (id) => {
        return instance.post(`/healthRecordTemplates/findById`, { id: id }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    updateHealthRecordTemplate = (payload) => {
        return instance.post(`/healthRecords/update`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    switchHealthRecordTemplate = (payload) => {
        return instance.post(`/healthRecords/switchTemplate`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    createFamilyMember = (payload) => {
        return instance.post(`/familymember/upsert`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    createHealthRecord = (payload) => {
        return instance.post(`/healthRecords/create`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getFamilyMembers = (payload) => {
        let url = '', attr = {}
        if (payload.type === 'phone') {
            url = '/familymember/list/limitByPhone'
            attr = { phone: payload.queryString, hospitalid: payload.hospitalid }
        } else {
            url = '/familymember/list/limitByName'
            attr = { name: payload.queryString, hospitalid: payload.hospitalid }
        }
        return instance.post(url, attr).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    createFamilyMember = (payload) => {
        return instance.post(`/familymember/create`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getMedicineList = (payload) => {
        return instance.post(`/medicines/pagingList`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getMedicineTypes = () => {
        return instance.post(`/medicines/medicineTypes`).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    upsertMedicine = (payload) => {
        return instance.post(`/medicines/upsert`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    deleteMedicine = (id) => {
        return instance.post(`/medicines/delete`, { id: id }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    updateFamilyInfo = (payload) => {
        return instance.post(`/familymember/upsert`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    updateAddress = (payload) => {
        return instance.post(`/addresses/upsert`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    whetherUpdateUserInfo = (payload) => {
        return instance.post(`/users/whetherUpdateUserInfo`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    updatePrescriptionHR = (payload) => {
        return instance.post(`/prescriptions/switchHR`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getMergeUsers = (payload) => {
        return instance.post(`/commons/hrMergeUsers`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    confirmMergeUser = (ids, userid, deleteUserIds, family_memberid, mergeFamilyMemberIds, hospitalid) => {
        return instance.post(`/users/mergeUser`, { ids: ids, userid: userid, deleteUserIds: deleteUserIds, family_memberid: family_memberid, mergeFamilyMemberIds: mergeFamilyMemberIds, hospitalid: hospitalid }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    updateHRTemplate = (payload) => {
        return instance.post(`/healthRecordTemplates/upsert`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    uploadHRPics = async (file, fileType, hrid) => {
        let ossInfo = await this.getOssInfo()
        let path = `healthRecords/${hrid}`
        let res = await OSS.uploadFileToOss(path, file, fileType, ossInfo)
        if (res.status === 200) {
            return this.getFileUrl(res.key)
        } else {
            this.showErrorTips()
            return res.error
        }
    }

    updateHRFiles = (payload) => {
        return instance.post(`/healthRecords/updateFiles`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    emptyMedicinePrice = (payload) => {
        return instance.post(`/medicinePrice/upsert`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    upsertCare = (payload) => {
        return instance.post(`/cares/upsert`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getRelatedCareInfo = (payload) => {
        return instance.post(`/cares/findLatestCareInfo`, payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    changeHRTemplatesSequence = (records) => {
        return instance.post(`/healthRecordTemplates/massUpsert`, { records: records }).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    upsertMedicinePrice = (payload) => {
        return this.request('/medicinePrice/upsert', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    getTreatmentLogDetail = (payload) => {
        return this.request('/healthRecords/findById', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    registerHospital = (payload) => {
        return instance.post('/hospitals/register', payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            instance.defaults.headers.common.Authorization = 'Beaer ' + res.data.token
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    updateHospital = (payload) => {
        return instance.post('/hospitals/update', payload).then(res => {
            if (res.data.status === 500) {
                this.showErrorTips()
            }
            return res.data
        }).catch(err => {
            this.showErrorTips(err.message.includes(networkError))
            return err
        })
    }

    getDoctorList = (payload) => {
        return this.request('/doctors/pagingList', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    getDiagnosisTemplates = (payload) => {
        return this.request('/diagnosisTemplates/all', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    upsertDiagnosisTemplate = (payload) => {
        return this.request('/diagnosisTemplates/upsert', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    changePassword = (payload) => {
        return this.request('/users/changePassword', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    findDoctorByPhone = (phone) => {
        return this.request('/doctors/findDoctorByPhone', 'POST', { phone: phone }).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    deleteHealthRecord = (payload) => {
        return this.request('/commons/deleteHealthRecord', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    getSubscribeAreaBarChart = (hospitalid) => {
        return this.request('/subscribes/getRecentlySubscribeStatusCount', 'POST', { hospitalid: hospitalid }).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    getSubscriberList = (hospitalid) => {
        return this.request('/subscribes/getSubscriberList', 'POST', { hospitalid: hospitalid }).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    getCurrentDateSchedule = (payload) => {
        return this.request('/hospitalSchedules/findByDate', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    updateHospitalScheduleLimit = (payload) => {
        return this.request('/hospitalSchedules/updateHospitalScheduleLimit', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    getCurrentMonthSchedule = (payload) => {
        return this.request('/hospitalSchedules/getCurrentMonthSchedule', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    resetHospitalSchedule = (payload) => {
        return this.request('/hospitalSchedules/resetHospitalSchedule', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

    generateHospitalSchedule = (payload) =>{
        return this.request('/hospitalSchedules/batchGenerateHospitalSchedule', 'POST', payload).then(res => {
            return res.data
        }).catch(err => {
            return err
        })
    }

}

export default new API()