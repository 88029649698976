import Vue from 'vue'
import App from './App.vue'
import index from '@/router/index'
import Router from 'vue-router'
import store from './store'
import ElementUI from 'element-ui'
import VueSignaturePad from 'vue-signature-pad'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'element-ui/lib/theme-chalk/index.css'
// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

Vue.component('vue-draggable-resizable', VueDraggableResizable)

export const eventBus = new Vue()

Vue.config.productionTip = false
Vue.use(Router)

Vue.use(ElementUI)
Vue.use(VueSignaturePad)


let routes = [...index]

const router = new Router({
  mode: 'history',
  routes: routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  let token = localStorage.getItem('Koa:token')
  if (token) {
    if (to.meta.path === 'login') {
      next({
        path: '/index'
      })
    } else {
      next()
    }
  } else {
    if (to.meta.isLogin) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  }
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})
