import io from 'socket.io-client'
import API from '@/utils/api'
import { MessageBox } from 'element-ui'

// initial state
const state = {
    socket: undefined,
    timeout: 1000 * 15
}

//mutations
const mutations = {}

//actions
const actions = {
    async socketStart ({
        state,
        commit,
        rootState,
        dispatch
    }) {
        // eslint-disable-next-line no-undef
        const socket = (state.socket = io(`${API.baseURL}/inquiry`))
        socket.on('connect', () => {
            console.log('SOCKET连接成功 → \n\n')
            commit('index/login', null, { root: true })
            socket.emit('login', { userid: rootState.index.userInfo.id })
        })

        socket.on('login', async () => {
            socket.emit('getRoomId')
            commit('index/online', null, { root: true })
            dispatch('ping')
        })

        socket.on('online_notification', async data => {
            console.log('上线用户信息 → ', data)
            commit('chat/addOnlineClientIds', [data.patientid], { root: true })
            await dispatch('chat/addInquiryUser', {
                doctorid: rootState.index.userInfo.id,
                patientid: [data.patientid],
                isReconnect: data.isReconnect
            }, { root: true })
            if (data.deleteUserIds && data.deleteUserIds.length) {
                commit('chat/removeUsers', data.deleteUserIds, { root: true })
            }
        })

        socket.on('online_patients', async data => {
            console.log('在线线用户信息 → ', data)
            commit('chat/addOnlineClientIds', data.patientids, { root: true })
            await dispatch('chat/getUsersByHealthRecordStatus', {
                doctorid: rootState.index.userInfo.id,
                patientid: [],
                online_patientids: data.patientids,
                status: rootState.chat.selectedKey
            }, { root: true })
        })

        socket.on('offline_notification', data => {
            commit('chat/removeOfflineClientIds', [data.patientid], { root: true })
            dispatch('chat/removeOfflineClient', {
                patientid: data.patientid
            }, { root: true })
            console.log('离线用户信息 → ', data)
        })

        socket.on('private_message', data => {
            data.created_date = new Date(data.created_date)
            dispatch('chat/pushReceiveMessageToClient', {
                message: data
            }, {
                root: true
            })
            console.log('新的消息 → ', data)
        })

        socket.on('msg_has_been_read', data => {
            commit('chat/markMessageAsReaded', {
                message: data
            }, {
                root: true
            })
            console.log('消息已读 → ', data)
        })

        socket.on('all_msgs_have_been_read', data => {
            commit('chat/markAllMessagesAsReaded', data, {
                root: true
            })
            console.log('全部消息已读 → ', data)
        })

        socket.on('inquiry_confirm', data => {
            dispatch('chat/handleInquiryformConfirmed', data, {
                root: true
            })
            socket.emit('inquiry_confirm', {
                recipient: data.sent_byid,
                health_recordid: data.health_recordid
            })
            console.log('用户已签名确认问诊表单 → ', data)
        })

        socket.on('confirm_inquiry_item', data => {
            dispatch('chat/handleInquiryformItemConfirmed', data, {
                root: true
            })
            console.log('用户已回答问诊表单问题 → ', data)
        })

        socket.on('connect_error', d => {
            console.log(`connect_error: ${d}`)
        })

        socket.on('connect_timeout', d => {
            console.log(`connect_timeout: ${d}`)
        })

        socket.on('disconnect', reason => {
            commit('index/offline', null, { root: true })
            if (reason === 'io server disconnect') {
                // the disconnection was initiated by the server
                MessageBox.confirm('您的账号已在其他地方登录', '连接已断开', {
                    confirmButtonText: '重新连接',
                    cancelButtonText: '退出',
                    type: 'warning'
                }).then(() => {
                    socket.connect()
                }).catch(() => {
                    commit('login/logout', null, { root: true })
                });
            }
            console.log(`disconnect: ${reason}`)
        })

        socket.on('reconnect', attemptNumber => {
            commit('index/online', null, { root: true })
            console.log(`reconnect success: ${attemptNumber}`)
        })

        socket.on('reconnect_failed', () => {
            console.log('reconnect_failed')
        })

        socket.on('reconnect_attempt', () => {
            commit('index/reconnect', null, { root: true })
            console.log('正在尝试重连')
        })

        socket.on('ping', () => {
            console.log('ping success')
        })

        socket.on('req_video_call', ({ recipient, roomId, status }) => {
            console.log({ recipient, roomId, status })
            commit('index/setIsDeclined', true, { root: true })
            setTimeout(() => {
                commit('index/shotdownRoom', null, { root: true })
                commit('index/closeStream', null, { root: true })
            }, 1000)
        })

        socket.on('roomId', roomId => {
            console.log('get roomId success ', roomId)
            commit('index/setRoomId', roomId, { root: true })
        })
    },
    typing () {
        if (this.socket) {
            this.isTyping = true
            this.socket.emit('typing')
        }
    },
    stopTyping () {
        if (this.socket) {
            this.isTyping = false
            this.socket.emit('stop typing')
        }
    },
    sendMessage ({
        state
    }, message) {
        if (state.socket) {
            console.log('向服务器发送数据 → ')
            console.log(message)
            state.socket.emit('private_message', message)
        }
    },
    markMessageAsReaded ({
        state
    }, message) {
        if (state.socket) {
            console.log('标记消息已读 → ', message)
            state.socket.emit('msg_has_been_read', message)
        }
    },
    markAllMessagesAsReaded ({
        state
    }, info) {
        if (state.socket) {
            console.log('标记全部消息已读 → ', info)
            state.socket.emit('all_msgs_have_been_read', info)
        }
    },
    // 心跳检测
    ping ({ state }) {
        setTimeout(() => {
            state.socket.emit('ping')
        }, state.timeout)
    },

    //拨打或者挂断视频通话
    videoCall ({ state }, { recipient, roomId, status }) {
        if (state.socket) {
            console.log('视频 操作 → ', status ? '拨打' : '挂断')
            state.socket.emit('req_video_call', { recipient: recipient, roomId: roomId, status: status })
        }
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}
