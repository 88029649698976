// initial state
import API from '@/utils/api'
import { Message } from 'element-ui'
const state = {
    doctors: [],
    pageSize: 15, // 每页显示的个数
    pageNumber: null, // 有多少页
    pageIndex: 0, // 当前页码
    totalNumber: 0, // 有多少条记录
    loading: false,
    roleMapping: {
        'Administrator': '管理员',
        'Doctor': '医生',
        'Pharmacist': '药剂师',
        'Editor': '内容编辑',
        'Manager': '助手管理'
    },
    completeCreate: true
}

// getters
const getters = {

}

//mutations
const mutations = {
    setDoctorList (state, result) {
        result.list.forEach(item => {
            item.formatRoles = []
            if (item.role.length) {
                item.role.forEach(element => {
                    item.formatRoles.push({
                        value: element,
                        label: state.roleMapping[element]
                    })
                })
            }
        })
        state.doctors = [...state.doctors, ...result.list]
        state.pageNumber = result.pageNumber
        state.totalNumber = result.totalNumber
    },
    emptyDoctorList (state) {
        state.doctors = []
    },
    emptyDoctorPageNumber (state) {
        state.pageNumber = null
    },
    switchDoctorLoading (state) {
        state.loading = !state.loading
    },
    updateDoctor (state, result) {
        if (result.role.length) {
            result.formatRoles = []
            result.role.forEach(element => {
                result.formatRoles.push({
                    value: element,
                    label: state.roleMapping[element]
                })
            })
        }
        state.doctors.splice(state.doctors.findIndex(doctor => doctor.id === result.id), 1, result)
    },
    setCompleteCreate (state, result) {
        state.completeCreate = result
    }
}

//actions
const actions = {
    async getDoctorList ({ state, rootState, commit }, payload) {
        Object.assign(payload, { pageSize: state.pageSize, hospitalid: rootState.index.userInfo.hospitalid })
        let res = await API.getDoctorList(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            commit('setDoctorList', res.result)
            commit('switchDoctorLoading')
        } else {
            console.log(res.err)
        }
    },

    async updateUser ({ state }, payload) {
        let res = await API.updateUser(payload)
        if (res.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (res.status === 200) {
            console.log(state.doctors)
            // commit('updateUser', res.result)
            // commit('switchDoctorLoading')
        } else {
            console.log(res.err)
        }
    },

    async upsertDoctorInfo ({ commit, rootState }, payload) {
        let userRes = {}, doctorRes = {}
        if (payload.role) {
            payload.role = payload.role.join(',')
        }
        if (payload.userid) {
            userRes = await API.updateUser(Object.assign({}, payload, { id: payload.userid }))
        } else {
            let doctorRes = await API.findDoctorByPhone(payload.phone)
            if (doctorRes.status === 200 && doctorRes.result.length) {
                commit('setCompleteCreate', false)
                Message({
                    message: '该手机号已经被注册',
                    type: 'info'
                })
                return
            }
            userRes = await API.createUser(payload)
        }
        if (userRes.code === 0) {
            localStorage.removeItem('Koa:token')
            window.location.href = '/login'
        } else if (userRes.status === 200) {
            if (!payload.userid) {
                payload.userid = userRes.result[0].id
                payload.hospitalid = rootState.index.userInfo.hospitalid
            }
            doctorRes = await API.upsertDoctorInfo(payload)
            if (doctorRes.status === 200) {
                if (payload.id) {
                    commit('updateDoctor', Object.assign(doctorRes.result[0], { phone: payload.phone }))
                }
                Message({
                    message: '执行成功',
                    type: 'success'
                })
            }
        } else {
            console.log(userRes.err)
        }

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
